import { Child, EMPTY_PARENT, EMPTY_PARTNER, Person, PersonError } from "./persons";
import { EMPTY_COUNTRY, TenantRecord, TrackedRecord } from "./commons";
import { Country } from "./commons";
import { EMPTY_IMMERGENCY_CONTACT, ImmergencyContact, Relative, RelativeError } from "./relatives";
import {
  TravelDetails,
  TravelDetailsError,
  Passport,
  PassportErrors,
  EMPTY_PASSPORT,
} from "./travels";

export type ApplicantsDict = Record<string, Applicant>;
export type ApplicantDoc = { id: string; applicant: Applicant };

export interface Applicant extends TrackedRecord, TenantRecord {
  //id?: string;
  name: string;
  surname: string;
  maiden_name?: string;
  phone?: string;
  email?: string;
  gender?: string;
  nationality?: Country;
  additional_nationality?: Country;
  birth_date?: string;
  birth_place?: string;
  birth_country?: Country;
  UK_insurance?: boolean;
  visited_UK_doctor?: boolean;
  passport?: Passport;
  marital_status?: string;
  has_children?: boolean;
  children?: Child[];
  has_relatives_in_UK?: boolean;
  relatives_UK?: Relative[];
  residency_street?: string;
  residency_city?: string;
  residency_province?: string;
  residency_country?: Country;
  residency_zip?: string;
  residency_ownership?: string;
  residency_ownership_other?: string;
  residency_date_since?: string;
  immergency_contact?: ImmergencyContact;
  granted_UK_visa?: boolean;
  has_travelled_to_UK?: boolean;
  travel_details_UK?: TravelDetails[];
  travel_details_abroad?: TravelDetails[];
  has_worked_illegally?: boolean;
  required_to_leave_UK?: boolean;
  refused_visa?: boolean;
  refused_entry?: boolean;
  has_applied_for_visa?: boolean;
  has_travelled_abroad?: boolean;
  refused_visa_abroad?: boolean;
  deported_abroad?: boolean;
  deported_abroad_details?: string;
  war_crimes?: boolean;
  convictions?: boolean;
  terrorist_org_member?: boolean;
  not_good_character?: boolean;
  application_additional_information?: string;
  children_travelling?: boolean;
  parents_unknown?: boolean;
  // partner
  partner?: Person;
  // father
  father?: Person;
  // mother
  mother?: Person;
  work_experience?: string;
  has_driving_license?: boolean;
  english_level?: string;
  has_health_issues?: string;
  preferredLanguage?: string;
}

export interface ApplicantErrors {
  sponsorId?: string;
  agentId?: string;
  name: string;
  surname: string;
  application_status?: string;
  maiden_name?: string;
  phone?: string;
  email?: string;
  gender?: string;
  nationality?: string;
  additional_nationality?: string;
  birth_date?: string;
  UK_insurance?: string;
  visited_UK_doctor?: string;
  passport?: PassportErrors;
  marital_status?: string;
  has_children?: string;
  children?: Child[];
  birth_place?: string;
  birth_country?: string;
  has_relatives_in_UK?: string;
  relatives_UK?: RelativeError[];
  residency_street?: string;
  residency_city?: string;
  residency_province?: string;
  residency_country?: string;
  residency_zip?: string;
  residency_ownership?: string;
  residency_ownership_other?: string;
  residency_date_since?: string;
  immergency_contact?: ImmergencyContact;
  granted_UK_visa?: string;
  has_travelled_to_UK?: string;
  travel_details_UK?: TravelDetailsError[];
  travel_details_abroad?: TravelDetailsError[];
  has_worked_illegally?: string;
  required_to_leave_UK?: string;
  refused_visa?: string;
  refused_entry?: string;
  has_applied_for_visa?: string;
  has_travelled_abroad?: string;
  refused_visa_abroad?: string;
  deported_abroad?: string;
  deported_abroad_details?: string;
  war_crimes?: string;
  convictions?: string;
  terrorist_org_member?: string;
  not_good_character?: string;
  application_additional_information?: string;
  children_travelling?: string;
  // partner
  partner?: PersonError;

  parents_unknown?: string;
  //father
  father?: PersonError;
  //mother
  mother?: PersonError;

  work_experience?: string;
  has_driving_license?: string;
  english_level?: string;
  has_health_issues?: string;
  privacy_policy_accepted?: string;
  sufficient_funds?: string;
}

export interface ValidationResult {
  isValid: boolean;
  errors: ApplicantErrors;
}

export const EMPTY_MINIMUM_APPLICANT: Applicant = {
  name: "",
  surname: "",
  sponsorId: "",
};

export const EMPTY_APPLICANT = {
  sponsorId: "",
  name: "",
  surname: "",
  maiden_name: "",
  phone: "",
  email: "",
  gender: "",
  nationality: { ...EMPTY_COUNTRY },
  additional_nationality: { ...EMPTY_COUNTRY },
  birth_date: "",
  UK_insurance: false,
  visited_UK_doctor: false,
  passport: { ...EMPTY_PASSPORT },
  marital_status: "",
  has_children: false,
  children: [],
  birth_place: "",
  birth_country: { ...EMPTY_COUNTRY },
  has_relatives_in_UK: false,
  relatives_UK: [],
  residency_street: "",
  residency_city: "",
  residency_province: "",
  residency_country: { ...EMPTY_COUNTRY },
  residency_zip: "",
  residency_ownership: "",
  residency_ownership_other: "",
  residency_date_since: "",
  immergency_contact: { ...EMPTY_IMMERGENCY_CONTACT },
  granted_UK_visa: false,
  has_travelled_to_UK: false,
  travel_details_UK: [],
  travel_details_abroad: [],
  has_worked_illegally: false,
  required_to_leave_UK: false,
  refused_visa: false,
  refused_entry: false,
  has_applied_for_visa: false,
  has_travelled_abroad: false,
  refused_visa_abroad: false,
  deported_abroad: false,
  deported_abroad_details: "",
  war_crimes: false,
  convictions: false,
  terrorist_org_member: false,
  not_good_character: false,
  application_additional_information: "",
  children_travelling: false,
  //partner
  partner: { ...EMPTY_PARTNER },

  parents_unknown: false,
  //father
  father: { ...EMPTY_PARENT },

  // mother
  mother: { ...EMPTY_PARENT },

  work_experience: "",
  has_driving_license: false,
  english_level: "",
  has_health_issues: "",
};

export const EMPTY_APPLICANT_DOC: ApplicantDoc = {
  id: "",
  applicant: { ...EMPTY_MINIMUM_APPLICANT },
};

export const BOOL_APPLICANT_PROPERTIES = [
  "UK_insurance",
  "visited_UK_doctor",
  "biometric_pass",
  "has_children",
  "has_relatives_in_UK",
  "granted_UK_visa",
  "has_travelled_to_UK",
  "has_worked_illegally",
  "required_to_leave_UK",
  "refused_visa",
  "refused_entry",
  "has_applied_for_visa",
  "has_travelled_abroad",
  "refused_visa_abroad",
  "deported_abroad",
  "war_crimes",
  "convictions",
  "terrorist_org_member",
  "not_good_character",
  "children_travelling",
  "has_driving_license",
  "partner_lives_with_you",
  "parents_unknown",
];

export const PROPERTY_MAPPING: Record<string, string> = {
  id: "ID",
  name: "Name",
  surname: "Family name",
  application: "Application",
  maiden_name: "Maiden name",
  phone: "Phone",
  email: "Email",
  gender: "Gender",
  nationality: "Nationality",
  additional_nationality: "Additional Nationality",
  birth_date: "Birthdate",
  UK_insurance: "Has an UK insurance",
  visited_UK_doctor: "Has visited a doctor in the UK",
  passport: "Passport",
  marital_status: "Marital statur",
  has_children: "Has children",
  children: "Children",
  birth_place: "Place of Birth",
  birth_country: "Country of Birth",
  has_relatives_in_UK: "Has relatives in the UK",
  relatives_UK: "Has relatives in the UK",
  residency_street: "Street",
  residency_city: "City",
  residency_province: "Province",
  residency_country: "Country of residence",
  residency_zip: "ZIP",
  residency_ownership: "Residency ownership",
  residency_ownership_other: "Residency ownership other",
  residency_date_since: "Residency since",
  immergency_contact: "Immergency contact",
  granted_UK_visa: "Has been granted UK visa",
  has_travelled_to_UK: "Has travelled to the UK",
  travel_details_UK: "",
  travel_details_abroad: "",
  has_worked_illegally: "Has worked illegally",
  required_to_leave_UK: "Required to leave the UK",
  refused_visa: "Has been refused visa",
  refused_entry: "Has been refused entry",
  has_applied_for_visa: "Has applied for visa in the past",
  has_travelled_abroad: "Has travelled abroad",
  refused_visa_abroad: "Has been refused visa abroad",
  deported_abroad: "Has been deported abroad",
  deported_abroad_details: "",
  war_crimes: "Has committed war crimes",
  convictions: "Has convictions",
  terrorist_org_member: "Terrorist org member",
  not_good_character: "Not a good character",
  application_additional_information: "Additional info",
  children_travelling: "Are children travelling with",
  parents_unknown: "Unknown parents",
  // partner
  partner: "Partner",
  // father
  father: "Father",
  // mother
  mother: "Mother",
  work_experience: "Work experience",
  has_driving_license: "Has a driving license",
  english_level: "English level",
  has_health_issues: "Has health issues",
  country: "Country",
  issue_date: "Issue date",
  expiry_date: "Expiry date",
  isBiometric: "Biometric passport",
  issuing_country: "Issuing country",
  issuing_place: "Issuing place",
  destination: "Destination",
  from: "From",
  to: "To",
  dataFormCompleted: "Data form completed at:",
  privacy_policy_accepted: "Accepted privacy policy",
};
