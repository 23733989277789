import React, { useContext, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";

import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  SvgIcon,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { Card, CardActions, CardContent, CardHeader, Grid } from "@mui/material";
import { TableCell, TableRow } from "@mui/material";
import { Delete as DeleteIcon, InfoOutlined as InfoIcon } from "@mui/icons-material";

import {
  DEFAULT_WORK_ORDER_DURATION,
  EmployersDict,
  SeasonsDict,
  WorkOrderDoc,
} from "../../types/orders";

import { TitledPage } from "../../components/titled-page";
import { WorkOrdersDict, WorkOrder, WorkOrderStatus } from "../../types/orders";
import { AuthContext } from "../../components/auth-provider";
import {
  deleteWorkOrder,
  loadSeasons,
  loadWorkOrders,
  storeWorkOrder,
  updateBatchWorkOrders,
} from "../../data-functions/orders-api";
import { loadEmployers, loadSponsors } from "../../data-functions/system-data_api";
import { loadApplications } from "../../data-functions/applications-api";
import { SponsorsDict } from "../../types/sponsors";
import { HeadCells, Order, getComparator } from "../../types/tables";
import { EnhancedTableHeadContents } from "../../components/enhanced-table";
import { formatDateStringToddMMYYYY, isDateInThePast } from "../../utils/utils";
import { CustomSwitch } from "../../components/custom-switch";
import WorkOrdersBatchUpdateDialog, {
  WOBatchUpdateProps,
  WOBatchUpdateResult,
} from "../dialogs/dialogWorkOrdersBatchUpdate";
import { TableVirtuoso } from "react-virtuoso";
import { VirtuosoTableComponents } from "../../components/enhanced-virtuoso-table";
import { AgentsDict } from "../../types/agents";
import { loadAgents } from "../../data-functions/agent-api";
import { DocumentHistoryDialog } from "../dialogs/dialogDocumentHistory";
import { hasRole } from "../../utils/user-utils";

const headCells: HeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sortable: true,
  },
  {
    id: "start_date",
    numeric: false,
    disablePadding: false,
    label: "Start",
    sortable: true,
    width: "96px",
  },
  {
    id: "end_date",
    numeric: false,
    disablePadding: false,
    label: "End",
    sortable: true,
    width: "96px",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    align: "center",
    sortable: true,
    width: "80px",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    sortable: false,
    align: "right",
    width: "48px",
  },
];

const EMPTY_ERRORS = {
  name: "",
  employer: "",
  sponsor: "",
  season: "",
  start_date: "",
  end_date: "",
  status: "",
  demand: "",
};

const PageWorkOrders: React.FC = () => {
  const [employers, setEmployers] = useState<EmployersDict>({});
  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [agents, setAgents] = useState<AgentsDict>({});
  const [seasons, setSeasons] = useState<SeasonsDict>({});
  const [workOrders, setWorkOrders] = useState<WorkOrdersDict>({});
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrderDoc | null>(null);
  const [selectedSeason, setSelectedSeason] = useState<string>("");

  const [searchValue, setSearchValue] = useState<string>("");
  const [showOpen, setShowOpen] = useState<boolean>(true);

  const [adminSponsorId, setAdminSponsorId] = useState<string>("");
  const [adminAgentId, setAdminAgentId] = useState<string>("");

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const [openBatchUpdate, setOpenBatchUpdate] = useState(false);
  const [batchUpdateResult, setBatchUpdateResult] = useState<WOBatchUpdateResult | null>(null);

  const [openHistory, setOpenHistory] = useState(false);
  const [historyDoc, setHistoryDoc] = useState<string>("");

  const [errors, setErrors] = useState<{
    name: string;
    employer: string;
    sponsor: string;
    season: string;
    start_date: string;
    end_date: string;
    status: string;
    demand: string;
  }>(EMPTY_ERRORS);

  // const { confirm } = useContext(ConfirmationDialogContext);

  const { currentUser } = useContext(AuthContext)!;

  //  const theme = useTheme();
  // const midScreen = useMediaQuery(theme.breakpoints.up("xs"));

  const fetchEmployers = async () => {
    const employersDict: EmployersDict = await loadEmployers(currentUser?.appUser);

    setEmployers(employersDict);
  };

  const fetchSponsors = async () => {
    const sponsorsDict: SponsorsDict = await loadSponsors(currentUser?.appUser);
    setSponsors(sponsorsDict);
  };

  const fetchAgents = async () => {
    const agentsDict: AgentsDict = await loadAgents(currentUser?.appUser);
    setAgents(agentsDict);
  };

  const fetchSeasons = async () => {
    const seasonsDict: SeasonsDict = await loadSeasons(currentUser?.appUser);
    const year = new Date().getFullYear();
    const seasonId = Object.entries(seasonsDict).find(([id, season]) => {
      return new Date(season.start_date).getFullYear() === year;
    })?.[0];
    setSeasons(seasonsDict);
    if (seasonsDict && seasonId) setSelectedSeason(seasonId);
  };

  const fetchWorkOrders = async () => {
    const workOrdersDict: WorkOrdersDict = await loadWorkOrders(currentUser?.appUser);
    setWorkOrders(workOrdersDict);
  };

  useEffect(() => {
    fetchEmployers();
    fetchSponsors();
    fetchAgents();
    fetchSeasons();
    fetchWorkOrders();
  }, []);

  useEffect(() => {
    const sponsorId = currentUser?.appUser?.sponsorId || "";
    const agentId = currentUser?.appUser?.agentId || "";

    if (adminSponsorId === sponsorId && adminAgentId === agentId) {
      return;
    }

    setAdminSponsorId(sponsorId);
    setAdminAgentId(agentId);

    setSelectedWorkOrder(null);
    fetchEmployers();
    fetchSponsors();
    fetchAgents();
    fetchSeasons();
    fetchWorkOrders();
  }, [currentUser]);

  const findSeasonId = (year: number): string | undefined => {
    return Object.entries(seasons).find(([id, season]) => {
      return new Date(season.start_date).getFullYear() === year;
    })?.[0];
  };

  const handleAddWorkOrder = async () => {
    const date = new Date();
    const seasonId = findSeasonId(date.getFullYear());

    const workOrder: WorkOrder = {
      name: "",
      start_date: date.toISOString().slice(0, 10),
      employerId: "",
      seasonId: seasonId || "",
      sponsorId: adminSponsorId,
      status: "open",
      demand: 0,
    };

    if (adminAgentId) {
      workOrder.agentId = adminAgentId;
    }

    date.setDate(date.getDate() + DEFAULT_WORK_ORDER_DURATION);
    workOrder.end_date = date.toISOString().slice(0, 10);

    setErrors(EMPTY_ERRORS); // Clear errors
    setSelectedWorkOrder({ id: "", workOrder });
  };

  const handleSaveWorkOrder = async () => {
    if (!validate()) {
      return;
    }

    selectedWorkOrder?.workOrder.name.trim();
    if (selectedWorkOrder && selectedWorkOrder.workOrder.demand === undefined) {
      selectedWorkOrder.workOrder.demand = 0;
    }

    const newWorkOrder = await storeWorkOrder(currentUser?.appUser, selectedWorkOrder, true);

    console.log(newWorkOrder);
    // Check if document exists
    if (newWorkOrder) {
      const newWorkOrders = { ...workOrders };
      newWorkOrders[newWorkOrder.id] = { ...newWorkOrder.workOrder };
      setWorkOrders(newWorkOrders);
      setSelectedWorkOrder(newWorkOrder);
    } else {
      console.log(
        `The work order could not be stored: ${selectedWorkOrder?.workOrder.name} (${selectedWorkOrder?.id}})`
      );
    }
  };

  const handleDelete = async (workOrderId: string) => {
    if (!workOrderId) return;

    const applications = await loadApplications(
      currentUser?.appUser,
      { workOrderId: workOrderId },
      3
    );

    if (Object.keys(applications).length > 0) {
      window.alert("This order has applications assosiated with it and cannot be deleted!");
      return;
    }

    if (window.confirm("Are you sure you want to delete this order?")) {
      deleteWorkOrder(workOrderId).then(() => {
        const newWorkOrders = { ...workOrders };
        delete newWorkOrders[workOrderId];
        setSelectedWorkOrder(null);
        setWorkOrders(newWorkOrders);
      });
    }
  };

  const handleSelectWorkOrder = async (workOrderId: string) => {
    if (workOrderId) {
      const workOrder = workOrders[workOrderId];
      if (workOrder) {
        if (selectedWorkOrder && selectedWorkOrder?.id !== workOrderId) {
          const sWO = selectedWorkOrder.id ? workOrders[selectedWorkOrder.id] : null;

          if (!isEqual(sWO, selectedWorkOrder.workOrder)) {
            const conf = window.confirm(
              "The selected work order has been changed! Press OK if you want to discard the changes."
            );
            if (!conf) {
              return;
            }
          }
        }

        setSelectedWorkOrder({ id: workOrderId, workOrder: { ...workOrder } });
        console.log("selecred order: ", { id: workOrderId, workOrder });
      }
    }
  };

  const handleShowHistory = async (documentId: string) => {
    setHistoryDoc(documentId);
    setOpenHistory(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.name + ": " + event.target.value);
    if (selectedWorkOrder) {
      let changeDate = false;

      if (
        event.target.name === "start_date" &&
        selectedWorkOrder.workOrder.name.endsWith(
          "_" + formatDateStringToddMMYYYY(selectedWorkOrder.workOrder.start_date)
        )
      ) {
        console.log("setting change date to true");
        changeDate = true;
      }

      const newWorkOrder: WorkOrderDoc = {
        ...selectedWorkOrder,
        workOrder: {
          ...selectedWorkOrder.workOrder,
          [event.target.name]: event.target.value,
        },
      };

      if (event.target.name === "start_date") {
        const endDate = new Date(newWorkOrder.workOrder.start_date);
        endDate.setDate(endDate.getDate() + DEFAULT_WORK_ORDER_DURATION);
        newWorkOrder.workOrder.end_date = endDate.toISOString().slice(0, 10);

        if (changeDate) {
          const name = newWorkOrder.workOrder.name;
          let lastUnderscoreIndex = name.lastIndexOf("_");
          newWorkOrder.workOrder.name =
            name.substring(0, lastUnderscoreIndex + 1) +
            formatDateStringToddMMYYYY(newWorkOrder.workOrder.start_date);
        }

        const year = new Date(newWorkOrder.workOrder.start_date).getFullYear();
        const seasonId = findSeasonId(year);
        if (seasonId && newWorkOrder.workOrder.seasonId !== seasonId) {
          newWorkOrder.workOrder.seasonId = seasonId;
        }
      }
      setSelectedWorkOrder(newWorkOrder);
    }
  };

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    const key = (event.target as Element).id;

    if (key && selectedWorkOrder) {
      const newWorkOrder: WorkOrderDoc = {
        ...selectedWorkOrder,
        workOrder: {
          ...selectedWorkOrder.workOrder,
          status: newValue as WorkOrderStatus,
        },
      };
      console.log(key + ": " + newValue);
      setSelectedWorkOrder(newWorkOrder);
    }
  };

  const onInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedWorkOrder) {
      return;
    }
    let changeName = false;
    const swo = selectedWorkOrder.workOrder;

    if (
      event.target.name === "employerId" &&
      (swo.name === "" ||
        swo.employerId === "" ||
        swo.name.startsWith(employers[swo.employerId].name + "_"))
    ) {
      changeName = true;
    }

    const value = event.target.value;
    const newWorkOrder: WorkOrderDoc = {
      id: selectedWorkOrder.id,
      workOrder: {
        ...selectedWorkOrder.workOrder,
        [event.target.name]: value,
      },
    };

    if (changeName) {
      console.log("change name");
      if (selectedWorkOrder.workOrder.name === "") {
        newWorkOrder.workOrder.name =
          employers[newWorkOrder.workOrder.employerId].name +
          "_Order XXXX_" +
          formatDateStringToddMMYYYY(newWorkOrder.workOrder.start_date);
      } else {
        const name = newWorkOrder.workOrder.name;
        let firstUnderscoreIndex = name.indexOf("_");

        newWorkOrder.workOrder.name =
          employers[newWorkOrder.workOrder.employerId].name + name.substring(firstUnderscoreIndex);
      }
      console.log("new workorder: ", newWorkOrder.workOrder);
    }

    setSelectedWorkOrder(newWorkOrder);
  };

  const onSeasonInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("season changed", event.target.value);
    const value = event.target.value;
    setSelectedSeason(value);
  };

  const validate = () => {
    const newErrors = { ...EMPTY_ERRORS };
    let isValid = true;
    if (selectedWorkOrder) {
      if (!selectedWorkOrder.workOrder.name) {
        newErrors.name = "Please, enter a valid name for this employment order!";
        isValid = false;
      }

      if (!selectedWorkOrder.workOrder.sponsorId) {
        newErrors.sponsor = "Please, select a sponsor for this employment order!";
        isValid = false;
      }

      if (!selectedWorkOrder.workOrder.employerId) {
        newErrors.employer = "Please, select an employer for this employment order!";
        isValid = false;
      }

      if (selectedWorkOrder.workOrder.start_date && !selectedWorkOrder.workOrder.end_date) {
        newErrors.end_date = "Both dates need to be filled in!";
      }

      if (selectedWorkOrder.workOrder.end_date && !selectedWorkOrder.workOrder.start_date) {
        newErrors.start_date = "Both dates need to be filled in!";
      }

      if (!selectedWorkOrder.workOrder.status) {
        newErrors.status = "Please select a status for this employment order !";
      }

      if (
        selectedWorkOrder.workOrder.demand === undefined ||
        selectedWorkOrder.workOrder.demand <= 0
      ) {
        newErrors.demand = "Please enter the worker demand!";
        isValid = false;
      }

      if (!selectedWorkOrder.workOrder.seasonId) {
        newErrors.season = "Please select a season for this employment order!";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleBatchUpdateClick = async () => {
    setBatchUpdateResult(null);
    setOpenBatchUpdate(true);
  };

  const onBatchUpdate = async (values: WOBatchUpdateProps) => {
    if (values.allowClose) {
      const newWorkOrders = { ...workOrders };
      const updateIds: string[] = [];
      Object.entries(newWorkOrders).forEach(([workOrderId, workOrder]) => {
        if (workOrder.status === "open" && isDateInThePast(workOrder.start_date)) {
          workOrder.status = "closed";
          updateIds.push(workOrderId);
        }
      });
      console.log("found " + updateIds.length + " orders to close");
      if (updateIds.length > 0) {
        updateBatchWorkOrders(currentUser?.appUser, updateIds, { status: "closed" })
          .then(() => {
            setBatchUpdateResult({ status: "OK" });
            setWorkOrders(newWorkOrders);
          })
          .catch((err) => {
            setBatchUpdateResult({ status: "FAILED", message: err.message });
          });
      }
    }
  };

  const getWorkOrderStatusChip = (status: string | undefined) => {
    switch (status) {
      case "open":
        return <Chip label="Open" color="success" />;

      case "closed":
        return <Chip label="Closed" color="error" />;

      default:
        return <Chip label="n/a" variant="outlined" color="primary" />;
    }
  };

  /* ------------ Search functions -------------- */

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(
    a: [string, WorkOrder],
    b: [string, WorkOrder],
    orderBy: string
  ): number {
    const workOrder1 = a[1];
    const workOrder2 = b[1];
    if (!orderBy) {
      return 0;
    }

    // console.log("a1: ");
    // console.log(workOrder1);

    // console.log("a2: ");
    // console.log(workOrdern2);

    // console.log("orderBy: " + orderBy);

    if (workOrder1 && workOrder2) {
      let res: number = 0;
      switch (orderBy) {
        case "start_date":
          res = workOrder1.start_date && !workOrder2.start_date ? 1 : 0;
          if (res === 0) {
            res = !workOrder1.start_date && workOrder2.start_date ? -1 : 0;
          }
          if (res === 0 && workOrder1.start_date && workOrder2.start_date) {
            res =
              new Date(workOrder2.start_date).getTime() - new Date(workOrder1.start_date).getTime();
          }
          return res;

        case "end_date":
          // end date
          if (res === 0) {
            res = workOrder1.end_date && !workOrder2.end_date ? 1 : 0;
          }

          if (res === 0) {
            res = !workOrder1.end_date && workOrder2.end_date ? -1 : 0;
          }

          if (res === 0 && workOrder1.end_date && workOrder2.end_date) {
            res = new Date(workOrder2.end_date).getTime() - new Date(workOrder1.end_date).getTime();
          }
          return res;

        case "name":
          res = workOrder2.name.localeCompare(workOrder1.name);
          return res;

        case "status":
          return workOrder2.status.localeCompare(workOrder1.status);
        default:
          break;
      }
    } else {
      if (workOrder1 && !workOrder2) {
        return -1;
      } else if (!workOrder1 && workOrder2) {
        return 1;
      }
    }
    return 0;
  }

  const filteredOrders = useMemo(
    () =>
      Object.entries(workOrders).filter(([orderId, workOrder]) => {
        try {
          return (
            (selectedSeason === "" || workOrder.seasonId === selectedSeason) &&
            (!showOpen || workOrder.status === "open") &&
            (searchValue.length < 3 ||
              workOrder.name.toLowerCase().includes(searchValue.toLowerCase()) ||
              workOrder.start_date.toLowerCase().includes(searchValue.toLowerCase()) ||
              (workOrder.employerId &&
                employers[workOrder.employerId].name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (workOrder.status &&
                workOrder.status.toLowerCase().includes(searchValue.toLowerCase())))
          );
        } catch (err) {
          console.error("filter exception:");
          console.log(workOrder);
          return false;
        }
      }),
    [searchValue, showOpen, workOrders, selectedSeason]
  );

  const visibleRows = useMemo(
    () =>
      filteredOrders
        .slice()
        .sort(getComparator(order, orderBy as keyof WorkOrder, descendingComparator)),
    [order, orderBy, filteredOrders]
  );

  const sortedEmployers = useMemo(
    () => Object.entries(employers).sort(([, a], [, b]) => a.name.localeCompare(b.name)),
    [employers]
  );

  const sortedSeasons = useMemo(
    () => Object.entries(seasons).sort(([, a], [, b]) => a.name.localeCompare(b.name)),
    [seasons]
  );

  return (
    <TitledPage title="Employment Orders">
      <Box>
        <Box display={"flex"} sx={{ gap: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            onClick={handleAddWorkOrder}
          >
            Add New Order
          </Button>
          <WorkOrdersBatchUpdateDialog
            open={openBatchUpdate}
            onClose={() => setOpenBatchUpdate(false)}
            onBatchUpdate={onBatchUpdate}
            workOrders={workOrders}
            updateResult={batchUpdateResult}
          />

          <Tooltip title="Close past employment orders">
            <span>
              <IconButton
                color="primary"
                edge={"end"}
                sx={{ marginTop: "8px", marginBottom: "4px" }}
                onClick={handleBatchUpdateClick}
              >
                {/* <RuleFolderOutlined /> */}
                <SvgIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#1976d2"
                  >
                    <path d="M480-280q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm-46-248q11-5 22-8.5t24-3.5q42 0 71 29t29 71q0 13-3.5 24t-8.5 22L434-528Zm46 188q-42 0-71-29t-29-71q0-13 3.5-24t8.5-22l134 134q-11 5-22 8.5t-24 3.5ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H447l-80-80H160v480Zm0 0v-480 480Z" />
                  </svg>
                </SvgIcon>
              </IconButton>
            </span>
          </Tooltip>

          {hasRole(currentUser?.appUser, ["admin"]) && (
            <>
              {" "}
              <DocumentHistoryDialog
                open={openHistory}
                onClose={() => setOpenHistory(false)}
                documentId={historyDoc}
              />
              <Tooltip title="History">
                <span>
                  <IconButton
                    color="primary"
                    edge={"end"}
                    sx={{ marginTop: "8px", marginBottom: "4px" }}
                    disabled={!selectedWorkOrder}
                    onClick={() => handleShowHistory(selectedWorkOrder?.id || "")}
                  >
                    <InfoIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}

          <Divider orientation="vertical" flexItem sx={{ marginTop: "8px", marginBottom: "4px" }} />

          {/* ----------------- Season ---------------- */}
          <TextField
            name={"seasonId"}
            id={"seasonId"}
            label={"Season"}
            select
            onChange={onSeasonInputComboChange}
            value={selectedSeason}
            margin={"dense"}
            size="small"
            sx={{ width: "128px" }}
          >
            <MenuItem value="" key="no_season">
              <Typography fontStyle={"italic"}>no season selected</Typography>
            </MenuItem>
            {sortedSeasons.map(([seasonId, season]) => {
              return (
                <MenuItem value={seasonId} key={seasonId}>
                  {season.name}
                </MenuItem>
              );
            })}
          </TextField>

          {/* ----------------- Status ---------------- */}
          <Tooltip title={showOpen ? "Show only open orders" : "Show all orders"}>
            <CustomSwitch checked={showOpen} onClick={() => setShowOpen(!showOpen)} />
          </Tooltip>

          {/* ----------------- Search box ---------------- */}
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchValue}
            margin="dense"
            onChange={handleSearchValueChange}
            sx={{ minWidth: "250px" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1rem",
            minHeight: "600px",
            height: "calc(100vh - 250px)",
            paddingBottom: "4px",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: "0",
              maxWidth: `calc(100% - 450px)`,
              marginRight: "8px",
            }}
          >
            <TableVirtuoso
              data={visibleRows}
              context={{
                selectedId: selectedWorkOrder?.id || "",
                handleSelect: handleSelectWorkOrder,
              }}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() => (
                <EnhancedTableHeadContents
                  numSelected={selectedWorkOrder?.id ? 1 : 0}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={() => {}}
                  onRequestSort={handleRequestSort}
                  rowCount={visibleRows.length}
                  selectable={false}
                  headCells={headCells}
                />
              )}
              fixedFooterContent={() => (
                <TableRow>
                  <TableCell align="right" colSpan={headCells.length}>
                    {visibleRows.length + " "} Orders
                  </TableCell>
                </TableRow>
              )}
              itemContent={(index, [orderId, order]) => {
                return (
                  <>
                    <TableCell scope="row" size="small">
                      {order.name}
                    </TableCell>
                    <TableCell size="small">{order.start_date}</TableCell>
                    <TableCell size="small">{order.end_date}</TableCell>
                    <TableCell size="small">{getWorkOrderStatusChip(order.status)}</TableCell>
                    <TableCell align="right" size="small">
                      <Tooltip title="Delete">
                        <IconButton size="small" onClick={() => handleDelete(orderId || "")}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </>
                );
              }}
            />
          </Box>

          {/* ------------- Work Order Details ------------- */}
          <Box id="details" sx={{ width: "48%", maxWidth: "450px", flexGrow: 1 }}>
            {selectedWorkOrder && (
              <Card sx={{ height: "100%" }}>
                <CardHeader title={"Employment Order Details"} />
                <CardContent sx={{ height: "calc(100% - 154px)", overflow: "auto" }}>
                  <Grid container spacing={2} padding={2}>
                    {/* ----------- Sponsor ----------- */}
                    {!adminSponsorId && (
                      <Grid item xs={12}>
                        <TextField
                          name={"sponsorId"}
                          label={"Sponsor"}
                          select
                          error={!!errors.sponsor}
                          helperText={errors.sponsor}
                          onChange={onInputComboChange}
                          value={(selectedWorkOrder && selectedWorkOrder.workOrder.sponsorId) || ""}
                          required
                          fullWidth
                          margin={"dense"}
                        >
                          <MenuItem value="" key="no_sponsor">
                            <Typography fontStyle={"italic"}>no sponsor selected</Typography>
                          </MenuItem>
                          {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                            return (
                              <MenuItem value={sponsorId} key={sponsorId}>
                                {sponsor.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    )}

                    {/* ----------- Agent ----------- */}
                    {!adminAgentId && (
                      <Grid item xs={12}>
                        <TextField
                          name={"agentId"}
                          label={"Agent"}
                          select
                          error={!!errors.sponsor}
                          helperText={errors.sponsor}
                          onChange={onInputComboChange}
                          value={(selectedWorkOrder && selectedWorkOrder.workOrder.agentId) || ""}
                          required
                          fullWidth
                          margin={"dense"}
                        >
                          <MenuItem value="" key="no_agent">
                            <Typography fontStyle={"italic"}>no agent selected</Typography>
                          </MenuItem>
                          {Object.entries(agents).map(([agentId, agent]) => {
                            return (
                              <MenuItem value={agentId} key={agentId}>
                                {agent.company}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    )}

                    {/* ----------- Employers ----------- */}
                    <Grid item xs={12}>
                      <TextField
                        name={"employerId"}
                        label={"Employer"}
                        select
                        error={!!errors.employer}
                        helperText={errors.employer}
                        onChange={onInputComboChange}
                        value={(selectedWorkOrder && selectedWorkOrder.workOrder.employerId) || ""}
                        required
                        fullWidth
                        margin={"dense"}
                      >
                        <MenuItem value="" key="no_employer">
                          <Typography fontStyle={"italic"}>no employer selected</Typography>
                        </MenuItem>
                        {sortedEmployers.map(([employerId, employer]) => {
                          return (
                            <MenuItem value={employerId} key={employerId}>
                              {employer.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        error={!!errors.name}
                        helperText={errors.name}
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={selectedWorkOrder && selectedWorkOrder.workOrder.name}
                        onChange={handleInputChange}
                      />
                    </Grid>

                    {/* ----------- Dates ----------- */}
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="start_date"
                        label="Order start date "
                        error={!!errors.start_date}
                        helperText={errors.start_date}
                        type="date"
                        value={(selectedWorkOrder && selectedWorkOrder.workOrder.start_date) || ""}
                        onChange={handleInputChange}
                        margin={"dense"}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="end_date"
                        label="Order end date "
                        error={!!errors.end_date}
                        helperText={errors.end_date}
                        type="date"
                        value={(selectedWorkOrder && selectedWorkOrder.workOrder.end_date) || ""}
                        onChange={handleInputChange}
                        margin={"dense"}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                      />
                    </Grid>

                    {/* ----------------- Season ---------------- */}

                    <Grid item xs={12} md={6}>
                      <TextField
                        name={"seasonId"}
                        label={"Season"}
                        select
                        error={!!errors.season}
                        helperText={errors.season}
                        onChange={onInputComboChange}
                        value={(selectedWorkOrder && selectedWorkOrder.workOrder.seasonId) || ""}
                        required
                        fullWidth
                        margin={"dense"}
                      >
                        <MenuItem value="" key="no_season">
                          <Typography fontStyle={"italic"}>no season selected</Typography>
                        </MenuItem>
                        {sortedSeasons.map(([seasonId, season]) => {
                          return (
                            <MenuItem value={seasonId} key={seasonId}>
                              {season.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="demand"
                        label="Worker demand"
                        error={!!errors.demand}
                        helperText={errors.demand}
                        type="number"
                        value={(selectedWorkOrder && selectedWorkOrder.workOrder.demand) || 0}
                        onChange={handleInputChange}
                        margin={"dense"}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                      />
                    </Grid>

                    {/* ----------------- Status ---------------- */}
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <FormLabel>Status</FormLabel>
                        <ToggleButtonGroup
                          id="status"
                          value={(selectedWorkOrder && selectedWorkOrder.workOrder.status) || ""}
                          exclusive
                          onChange={handleToggleChange}
                          aria-label="Order Status"
                          sx={{
                            marginTop: "8px",
                          }}
                        >
                          <ToggleButton
                            id="status_open"
                            value="open"
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: (theme) => theme.palette.success.main,
                                color: (theme) => theme.palette.success.contrastText,
                                "&:hover": {
                                  backgroundColor: (theme) => theme.palette.success.dark,
                                },
                              },
                            }}
                          >
                            Open
                          </ToggleButton>
                          <ToggleButton
                            id="status_closed"
                            value="closed"
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: (theme) => theme.palette.error.main,
                                color: (theme) => theme.palette.error.contrastText,
                                "&:hover": {
                                  backgroundColor: (theme) => theme.palette.error.dark,
                                },
                              },
                            }}
                          >
                            closed
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <FormHelperText error={!!errors.status}>{errors.status}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ padding: 2 }}>
                  <Box flexGrow={1}></Box>
                  <Button variant="contained" onClick={handleSaveWorkOrder} sx={{ mr: 2 }}>
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setSelectedWorkOrder(null);
                    }}
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </Button>
                </CardActions>
              </Card>
            )}
          </Box>
        </Box>
      </Box>
    </TitledPage>
  );
};

export default PageWorkOrders;
