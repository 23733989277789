import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Divider, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { Card, CardActions, CardContent, CardHeader, Grid } from "@mui/material";
import { TableCell } from "@mui/material";
import { Delete as DeleteIcon, InfoOutlined as InfoIcon } from "@mui/icons-material";
// import { MuiColorInput, MuiColorInputColors, matchIsValidColor } from "mui-color-input";
import EditIcon from "@mui/icons-material/Edit";

import { Employer, EmployerDoc, EmployersDict } from "../../types/orders";
import { ref, getDownloadURL, uploadString } from "firebase/storage";

import { storage } from "../../types/firebase";
import { isEMailValid } from "../../utils/utils";

import header_Image from "../../ressources/ibc_logo.png";
import checked_background from "../../ressources/checked_background2.png";
import { AddressInput } from "../../components/address-input";
import { AddressErrors, Country, EMPTY_COUNTRY } from "../../types/commons";
import { TitledPage } from "../../components/titled-page";
import { deleteEmployer, loadEmployers, storeEmployer } from "../../data-functions/system-data_api";
import { AuthContext } from "../../components/auth-provider";
import { HeadCells, Order, getComparator } from "../../types/tables";
import { TableVirtuoso } from "react-virtuoso";
import { VirtuosoTableComponents } from "../../components/enhanced-virtuoso-table";
import { EnhancedTableHeadContents } from "../../components/enhanced-table";
import { isEqual } from "lodash";
import { hasRole } from "../../utils/user-utils";
import { DocumentHistoryDialog } from "../dialogs/dialogDocumentHistory";

const headCells: HeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sortable: true,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    sortable: false,
    align: "right",
  },
];

const EMPTY_ADDRESS = {
  street: "",
  zip: "",
  city: "",
  country: { code: "GB", label: "United Kingdom", phone: "44" },
};

const EmployersData: React.FC = () => {
  const [employers, setEmployers] = useState<EmployersDict>({});

  const [selectedEmployer, setSelectedEmployer] = useState<EmployerDoc | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const [openHistory, setOpenHistory] = useState(false);
  const [historyDoc, setHistoryDoc] = useState<string>("");

  const { currentUser } = useContext(AuthContext)!;

  const [errors, setErrors] = useState<{
    name: string;
    email: string;
    phone: string;
    // color: string;
    address: AddressErrors;
  }>({ name: "", email: "", phone: "", address: {} });

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const fetchEmployers = async () => {
    const employersDict: EmployersDict = await loadEmployers(currentUser?.appUser);
    setEmployers(employersDict);
  };

  useEffect(() => {
    fetchEmployers();
  }, []);

  const handleAddEmployer = async () => {
    const employer = { name: "New Employer", address: { ...EMPTY_ADDRESS } };
    setErrors({ name: "", email: "", phone: "", address: {} });
    setSelectedEmployer({ id: "", employer });
  };

  const handleSaveEmployer = async () => {
    if (!(selectedEmployer && validate())) {
      return;
    }
    console.log("selected id: " + selectedEmployer.id);
    console.log("selected employer: ");
    console.log(selectedEmployer);

    // Check if document exists
    if (selectedEmployer) {
      // upload the new image if there's any
      const imageURL = await handleUploadAndSaveLogo();
      if (imageURL) {
        selectedEmployer.employer.logo = imageURL;
        setImageSrc(null);
      }

      // Update the document
      selectedEmployer.employer.name.trim();
      const newEmployer = await storeEmployer(currentUser?.appUser, selectedEmployer, true);

      if (newEmployer) {
        const newEmployers = { ...employers };
        newEmployers[newEmployer.id] = { ...newEmployer.employer };
        setEmployers(newEmployers);
        setSelectedEmployer(newEmployer);
      }
    } else {
      console.log("No employer selected");
    }
  };

  const handleDelete = async (employerId: string) => {
    if (!employerId) return;

    if (window.confirm("Are you sure you want to delete this employer?")) {
      const result = await deleteEmployer(currentUser?.appUser, employerId);

      switch (result) {
        case "success":
          const newEmployers = { ...employers };
          delete newEmployers[employerId];
          if (selectedEmployer?.id === employerId) {
            setSelectedEmployer(null);
          }
          setEmployers(newEmployers);
          break;
        case "no-employer":
          window.alert("The employer does not exist");
          break;
        case "employer-in-use":
          window.alert("The employer is linked to other data and cannot be deleted");
          break;
        case "insufficient-permissions":
          window.alert("You do not have enough permissions to delete this employer.");
          break;

        default:
          console.error("Unknown error");
      }
    }
  };

  const handleSelectEmployer = (employerId: string) => {
    if (employerId) {
      const employer = employers[employerId];
      if (employer) {
        if (selectedEmployer && selectedEmployer?.id !== employerId) {
          const sEmp = selectedEmployer.id ? employers[selectedEmployer.id] : null;

          if (!isEqual(sEmp, selectedEmployer.employer)) {
            const conf = window.confirm(
              "The selected emloyer has been changed! Press OK if you want to discard the changes."
            );
            if (!conf) {
              return;
            }
          }
        }
        console.log("selected emloyer: " + employerId);
        console.log(employer);
        setSelectedEmployer({ id: employerId, employer: { ...employer } });
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("selected id: " + selectedEmployer?.id);
    console.log("selected employer: ");
    console.log(selectedEmployer);
    //    if (event.target.name === "expiry_date") {
    if (selectedEmployer) {
      const newEmployer: EmployerDoc = {
        ...selectedEmployer,
        employer: {
          ...selectedEmployer.employer,
          [event.target.name]: event.target.value,
        },
      };

      setSelectedEmployer(newEmployer);
    }
  };

  const handleAddressInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("address input change");
    console.log("target: " + event.target.name);
    console.log("new value: " + event.target.value);

    if (selectedEmployer) {
      const newEmployer: EmployerDoc = { ...selectedEmployer };
      if (!newEmployer.employer.address) {
        newEmployer.employer.address = { ...EMPTY_ADDRESS };
      }
      newEmployer.employer.address = {
        ...newEmployer.employer.address,
        [event.target.name]: event.target.value,
      };
      console.log(newEmployer);
      setSelectedEmployer(newEmployer);
    }
  };

  const handleAddressCountryChange = (event: any, newValue: Country | null) => {
    if (selectedEmployer) {
      const newEmployer: EmployerDoc = { ...selectedEmployer };
      if (!newEmployer.employer.address) {
        newEmployer.employer.address = { ...EMPTY_ADDRESS };
      }
      newEmployer.employer.address.country = newValue ?? { ...EMPTY_COUNTRY };
      setSelectedEmployer(newEmployer);
    }
  };

  const validate = () => {
    const newErrors = { name: "", email: "", phone: "", address: {} };
    let isValid = true;
    if (selectedEmployer && selectedEmployer.employer.name.length < 3) {
      newErrors.name = "Please, enter a valid name for this employer!";
      isValid = false;
    }

    if (selectedEmployer && !isEMailValid(selectedEmployer.employer.email)) {
      newErrors.email = "Please, enter a valid email addres, for this employer!";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  // const handleColorChange = (newValue: string, colors: MuiColorInputColors) => {
  //   if (matchIsValidColor(newValue) && selectedEmployer) {
  //     const newEmployer: Employer = {
  //       ...selectedEmployer,
  //       color: newValue,
  //     };
  //     setSelectedEmployer(newEmployer);
  //   } // boolean
  // };

  /*--------- Image Upload ----------- */
  const handleImageClick = () => {
    console.log("open image select");
    fileInputRef.current?.click();
    //setIsDialogOpen(true);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e: ProgressEvent<FileReader>) {
        if (e.target && e.target.result) {
          setImageSrc(e.target.result as string);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUploadAndSaveLogo = async () => {
    if (imageSrc && selectedEmployer) {
      // Step 1: Check if the employer already has an image URL and delete it
      // commented out as the uploadString will replace the old one. should we use more pictures
      // uncomment the deletion again.

      //   if (selectedEmployer.url) {
      //     const oldImageRef = ref(storage, "employers/" + selectedEmployerId + ".jpg");
      //     try {
      //       await deleteObject(oldImageRef);
      //     } catch (error) {
      //       console.error("Failed to delete the old image:", error);
      //       return; // If the delete fails, we might not want to proceed with uploading the new image
      //     }
      //   }

      // Step 2: Upload the image to Firebase Storage
      const storageRef = ref(storage, "employers/" + selectedEmployer.id + ".jpg");
      await uploadString(storageRef, imageSrc, "data_url");

      // Step 3: Get download URL
      const downloadURL: string = await getDownloadURL(storageRef);

      return downloadURL;
    } else {
      // console.error("Either the image source is not available or no employer is selected.");
      return "";
    }
  };

  const handleShowHistory = async (documentId: string) => {
    setHistoryDoc(documentId);
    setOpenHistory(true);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /* ------------ Search functions -------------- */
  const filteredEmployers = Object.entries(employers).filter(
    ([employerId, employer]) =>
      searchValue.length < 3 ||
      employer.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      employer.email?.toLowerCase().includes(searchValue.toLowerCase())
  );

  const visibleRows = useMemo(
    () => filteredEmployers.slice().sort(getComparator(order, orderBy as keyof Employer)),
    [order, orderBy, filteredEmployers]
  );

  return (
    <TitledPage title="Employers">
      <Box>
        <Box display={"flex"} sx={{ width: "50%", gap: 2 }}>
          <Button variant="outlined" color="primary" size="small" onClick={handleAddEmployer}>
            Add New employer
          </Button>
          {hasRole(currentUser?.appUser, ["admin"]) && (
            <>
              <DocumentHistoryDialog
                open={openHistory}
                onClose={() => setOpenHistory(false)}
                documentId={historyDoc}
              />
              <Tooltip title="History">
                <span>
                  <IconButton
                    color="primary"
                    edge={"end"}
                    disabled={!selectedEmployer}
                    onClick={() => handleShowHistory(selectedEmployer?.id || "")}
                  >
                    <InfoIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ marginTop: "8px", marginBottom: "4px" }}
              />
            </>
          )}

          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{ minWidth: "300px" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1rem",
            minHeight: "845px",
            height: "calc(100vh - 250px)",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: "0",
              maxWidth: `calc(100% - 450px)`,
              marginRight: "8px",
            }}
          >
            <TableVirtuoso
              style={{ height: "100%" }}
              data={visibleRows}
              context={{ selectedId: selectedEmployer?.id, handleSelect: handleSelectEmployer }}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() => (
                <EnhancedTableHeadContents
                  numSelected={selectedEmployer?.id ? 1 : 0}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={() => {}}
                  onRequestSort={handleRequestSort}
                  rowCount={visibleRows.length}
                  headCells={headCells}
                />
              )}
              itemContent={(index, [employerId, employer]) => (
                <>
                  <TableCell scope="row" size="small">
                    {employer.name}
                  </TableCell>
                  <TableCell align="right" style={{ width: 120 }} size="small">
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(employerId || "");
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </>
              )}
            />
          </Box>

          {/* ------------- Employer Details ------------- */}
          <Box id="details" sx={{ width: "48%", maxWidth: "650px", flexGrow: 1 }}>
            {selectedEmployer && (
              <Card>
                <CardHeader title={"Employer Details"} />
                <CardContent>
                  <Grid container spacing={2} padding={2}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        error={!!errors.name}
                        helperText={errors.name}
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={selectedEmployer && selectedEmployer.employer.name}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        error={!!errors.email}
                        helperText={errors.email}
                        margin="dense"
                        name="email"
                        label="Email"
                        type="text"
                        fullWidth
                        value={(selectedEmployer && selectedEmployer.employer.email) || ""}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={!!errors.phone}
                        helperText={errors.phone}
                        margin="dense"
                        name="phone"
                        label="Phone"
                        type="text"
                        fullWidth
                        value={(selectedEmployer && selectedEmployer.employer.phone) || ""}
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <AddressInput
                        address={
                          selectedEmployer && selectedEmployer.employer.address
                            ? selectedEmployer.employer.address
                            : { ...EMPTY_ADDRESS }
                        }
                        errors={errors.address}
                        onInputChange={handleAddressInputChange}
                        onCountryChange={handleAddressCountryChange}
                        secondStreetLine={true}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                    <MuiColorInput
                      error={!!errors.color}
                      helperText={errors.color}
                      margin="dense"
                      name="color"
                      label="Employer's main color"
                      fullWidth
                      value={(selectedEmployer && selectedEmployer.color) || "#00ff00"}
                      onChange={handleColorChange}
                      format="hex"
                    />
                  </Grid> */}
                    <Grid item xs={12}>
                      <Box
                        position="relative"
                        display="inline-block"
                        sx={{
                          backgroundImage: `url(${checked_background})`,
                          backgroundRepeat: "repeat",
                        }}
                      >
                        <img
                          src={imageSrc || selectedEmployer?.employer.logo || header_Image}
                          onClick={handleImageClick}
                          alt="Employer"
                          style={{ maxWidth: "350px", cursor: "pointer" }}
                        />
                        <Box
                          position="absolute"
                          bottom={0}
                          width="100%"
                          height="50px"
                          bgcolor="rgba(0, 0, 0, 0.5)"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          gap="10px"
                          sx={{ cursor: "pointer" }}
                          onClick={handleImageClick}
                        >
                          <EditIcon sx={{ color: "white" }} />
                          <Typography variant="body2" color="white">
                            Click to change the logo
                          </Typography>
                        </Box>
                      </Box>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        ref={fileInputRef}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ padding: 2 }}>
                  <Box flexGrow={1}></Box>
                  <Button variant="contained" onClick={handleSaveEmployer} sx={{ mr: 2 }}>
                    Save
                  </Button>
                </CardActions>
              </Card>
            )}
          </Box>
        </Box>
      </Box>
    </TitledPage>
  );
};

export default EmployersData;
