import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";

import {
  APPLICATION_TYPES,
  ApplicationDoc,
  EMPTY_VISA_APPLICATION,
  VisaApplication,
} from "../../types/visa-application";
import { SponsorsDict } from "../../types/sponsors";
import { SeasonsDict, WorkOrdersDict } from "../../types/orders";
import { ApplicantDoc, ApplicantsDict, EMPTY_MINIMUM_APPLICANT } from "../../types/applicants";
import { applicantFullName } from "../../utils/applicant-utils";
import AddApplicantDialog from "./dialogApplicant";
import { loadSeasons, loadWorkOrders } from "../../data-functions/orders-api";
import { AuthContext } from "../../components/auth-provider";
import { loadSponsors } from "../../data-functions/system-data_api";
import { AgentsDict } from "../../types/agents";
import { loadAgents } from "../../data-functions/agent-api";
import { storeApplicant } from "../../data-functions/applicants-api";
import { VirtualPresentationInstance, EMPTY_VP_INSTANCE } from "../../types/virtual-presentations";
import { getDefaultExpiryDate } from "../../utils/utils";
import { loadApplication } from "../../data-functions/applications-api";

interface ApplicantOptionType {
  inputValue?: string;
  id: string;
  name: string;
}

interface ApplicationDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (application: ApplicationDoc) => void;
  application: ApplicationDoc;
  applicants: ApplicantsDict;
}

const EMPTY_APPL = {
  ...EMPTY_VISA_APPLICATION,
};

const EMPTY_APPLICATION_DOC = { id: "", application: { ...EMPTY_APPL } };

const EMPTY_ERRORS = {
  applicant: "",
  type: "",
  sponsor: "",
  agent: "",
  expiry_date: "",
  virtualform_expiry_date: "",
  cos: "",
  gwf: "",
  season: "",
  order: "",
};

const EMPTY_OPTION: ApplicantOptionType = {
  id: "",
  name: "Add new applicant",
};
const ApplicationDialog = ({
  open,
  onClose,
  onSave,
  application: initialApplication,
  applicants,
}: ApplicationDialogProps) => {
  const [appDoc, setApplication] = useState<ApplicationDoc>({
    id: "",
    application: { ...EMPTY_VISA_APPLICATION },
  });
  const [applicant, setApplicant] = useState<ApplicantOptionType>(EMPTY_OPTION);
  const [applicantOptions, setApplicantOptions] = useState<ApplicantOptionType[]>([]);
  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [orders, setOrders] = useState<WorkOrdersDict>({});
  const [adminSponsorId, setAdminSponsorId] = useState<string>("");
  const [adminAgentId, setAdminAgentId] = useState<string>("");
  const [agents, setAgents] = useState<AgentsDict>({});
  const [seasons, setSeasons] = useState<SeasonsDict>({});
  const [errors, setErrors] = useState<{
    applicant: string;
    type: string;
    sponsor: string;
    agent: string;
    expiry_date: string;
    virtualform_expiry_date?: string;
    cos?: string;
    gwf?: string;
    season?: string;
    order?: string;
  }>({ ...EMPTY_ERRORS });

  const [editApplicant, setEditApplicant] = useState<ApplicantDoc>({
    id: "",
    applicant: EMPTY_MINIMUM_APPLICANT,
  });
  const [openAddApplicant, toggleOpen] = useState(false);

  const { currentUser } = useContext(AuthContext)!;

  useEffect(() => {
    const initializeApplication = async () => {
      let newApplication: ApplicationDoc;
      if (initialApplication.application) {
        if (initialApplication.id) {
          const loadedApplication = await loadApplication(
            currentUser?.appUser,
            initialApplication.id
          );
          if (loadedApplication) {
            newApplication = loadedApplication;
          } else {
            newApplication = { ...initialApplication };
          }
        } else {
          newApplication = { ...initialApplication };
        }
      } else {
        newApplication = {
          id: "",
          application: {
            ...EMPTY_APPL,
            dataForm_expiry_date: getDefaultExpiryDate(),
            sponsorId: adminSponsorId,
            agentId: adminAgentId,
          },
        };
      }

      if (applicants) {
        const applId = newApplication.application.applicantId;
        let applOption: ApplicantOptionType;
        if (applId) {
          applOption = {
            id: applId,
            name: applicantFullName(applicants[applId]),
          };
        } else {
          applOption = { ...EMPTY_OPTION };
        }
        setApplicant(applOption);
      } else {
        setApplicant({ id: "", name: "Add new applicant" });
      }

      setApplication(newApplication);
    };

    initializeApplication();
  }, [initialApplication]);

  useEffect(() => {
    const options = Object.entries(applicants).map(([id, applicant]) => ({
      id,
      name: applicantFullName(applicant) + ` (${applicant.email})`,
    }));
    options.unshift({ id: "", name: "Add new applicant" });

    setApplicantOptions(options);
  }, [applicants]);

  useEffect(() => {
    const fetchSponsors = async () => {
      const sponsorsDict: SponsorsDict = await loadSponsors(currentUser?.appUser);

      if (sponsorsDict) {
        setSponsors(sponsorsDict);
      } else {
        setSponsors({});
      }
    };

    const fetchWorkOrders = async () => {
      const ordersDict: WorkOrdersDict = await loadWorkOrders(currentUser?.appUser);

      if (ordersDict) {
        setOrders(ordersDict);
      } else {
        setOrders({});
      }
    };

    const fetchAgents = async () => {
      const agentsDict: AgentsDict = await loadAgents(currentUser?.appUser);

      if (agentsDict) {
        setAgents(agentsDict);
      } else {
        setAgents({});
      }
    };

    const fetchSeasons = async () => {
      const seasonsDict: SeasonsDict = await loadSeasons(currentUser?.appUser);

      if (seasonsDict) {
        setSeasons(seasonsDict);
      } else {
        setSeasons({});
      }
    };

    const sponsorId = currentUser?.appUser?.sponsorId || "";
    setAdminSponsorId(sponsorId);

    const agentId = currentUser?.appUser?.agentId || "";
    setAdminAgentId(agentId);

    fetchSponsors();
    fetchWorkOrders();
    fetchAgents();
    fetchSeasons();
  }, [currentUser]);

  const filter = createFilterOptions<ApplicantOptionType>({
    stringify: (option) => option.name, // custom logic to convert option to string
  });

  const validateInput = () => {
    let newErrors = { ...EMPTY_ERRORS };
    let formIsValid = true;

    if (!appDoc.application.applicantId) {
      newErrors.applicant = "Please, provide a candidate for this application";
      formIsValid = false;
    }

    if (!appDoc.application.sponsorId) {
      newErrors.sponsor = "Please, provide a sponsor for this application";
      formIsValid = false;
    }

    if (!appDoc.application.seasonId) {
      newErrors.season = "Please, provide a season for this application";
      formIsValid = false;
    }

    if (
      appDoc.application.seasonId &&
      appDoc.application.workOrderId &&
      orders[appDoc.application.workOrderId].seasonId !== appDoc.application.seasonId
    ) {
      newErrors.order = "Work order does not belong to the selected season";
      formIsValid = false;
    }

    // else if (!application.id) {
    //   const applicationQuery = query(
    //     collection(db, "applications"),
    //     where("email", "==", application.email)
    //   );
    //   const applicationSnapshot = await getDocs(applicationQuery);

    //   if (!applicationSnapshot.empty) {
    //     newErrors.email = "application with this email address already exists";
    //     formIsValid = false;
    //   }
    // }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSave = async () => {
    if (!validateInput()) {
      return;
    }

    onSave(appDoc);
    setApplication({ ...EMPTY_APPLICATION_DOC });
    handleClose();
  };
  const handleClose = () => {
    setErrors({ ...EMPTY_ERRORS });
    onClose();
  };

  const onInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (appDoc) {
      const value = event.target.value;
      const newApplication: ApplicationDoc = {
        ...appDoc,
      };
      switch (event.target.name) {
        case "sponsorId":
          newApplication.application.sponsorId = value;
          break;
        case "agentId":
          newApplication.application.agentId = value;
          break;
        case "workOrderId":
          newApplication.application.workOrderId = value;
          break;

        case "seasonId":
          newApplication.application.seasonId = value;
          break;

        case "type":
          const applicationType = APPLICATION_TYPES.find((type) => type.key === value);
          if (applicationType) {
            newApplication.application.type = { ...applicationType };
          }
          break;

        default:
          break;
      }

      setApplication(newApplication);
    }
  };

  const handleVPInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.name) return;

    const newApplication: ApplicationDoc = appDoc ? { ...appDoc } : { ...EMPTY_APPLICATION_DOC };

    const key = event.target.name as keyof VirtualPresentationInstance;

    switch (key) {
      case "expiry_date":
        if (!newApplication.application.virtualPresentation) {
          newApplication.application.virtualPresentation = { ...EMPTY_VP_INSTANCE };
        }
        newApplication.application.virtualPresentation.expiry_date = event.target.value;
        break;
      default:
        break;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.name) return;

    const newApplication: ApplicationDoc = appDoc ? { ...appDoc } : { ...EMPTY_APPLICATION_DOC };

    const key = event.target.name as keyof VisaApplication;
    switch (key) {
      case "privacy_policy_accepted":
        newApplication.application[key] = event.target.value === "true";
        break;
      case "sufficient_funds":
        newApplication.application[key] = event.target.value === "true";
        break;

      case "dataForm_completed":
      case "dataForm_expiry_date":
        newApplication.application[key] = event.target.value;
        break;
      case "type":
        // type is being set in a separate handler
        break;
      case "virtualPresentation":
        return;

      default:
        newApplication.application[key] = event.target.value;
        break;
    }

    setApplication(newApplication);
  };

  const handleSaveApplicant = async (applicantDoc: ApplicantDoc) => {
    // const [appDoc, setApplication] = useState<ApplicationDoc>({
    //   id: "",
    //   application: { ...EMPTY_VISA_APPLICATION2 },
    // });
    // const [applicant, setApplicant] = useState<ApplicantOptionType | null>(null);
    // const [applicantOptions, setApplicantOptions] = useState<ApplicantOptionType[]>([]);

    console.log("handleSaveApplicant");
    console.log(applicantDoc);

    const newApplicant = { ...applicantDoc.applicant };

    const newApplicantId = (await storeApplicant(currentUser?.appUser, applicantDoc, true)) || "";

    console.log("New applicant id: " + newApplicantId);

    const newApplicantOption = {
      id: newApplicantId,
      name: applicantFullName(newApplicant),
    };

    applicantOptions.push(newApplicantOption);
    setApplicantOptions(applicantOptions);
    setApplicant(newApplicantOption);

    const newApplication = { ...appDoc };
    newApplication.application.applicantId = newApplicantId;
    setApplication(newApplication);
    console.log("newApplication");
    console.log(newApplication);
  };

  const handleApplicantClose = () => {
    toggleOpen(false);
  };

  const addNewApplicant = (inputValue: string = "") => {
    const newApplicant: ApplicantDoc = {
      id: "",
      applicant: {
        ...EMPTY_MINIMUM_APPLICANT,
        sponsorId: appDoc.application.sponsorId || "",
        agentId: appDoc.application.agentId || "",
      },
    };
    if (inputValue) {
      const lastSpaceIndex = inputValue.trim().lastIndexOf(" ");
      newApplicant.applicant.name = inputValue.substring(0, lastSpaceIndex).trim();
      newApplicant.applicant.surname = inputValue.substring(lastSpaceIndex + 1).trim();
    }
    setEditApplicant(newApplicant);
    toggleOpen(true);
  };

  const handleApplicantChange = (event: any, value: ApplicantOptionType | null) => {
    if (value && typeof value === "string") {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        addNewApplicant();
      });
    } else if (value && value.inputValue) {
      addNewApplicant(value.inputValue);
    } else {
      const newApplication: ApplicationDoc = { ...appDoc };
      newApplication.application.applicantId = value ? value.id : "";
      if (!value) {
        value = { id: "", name: "Add new applicant" };
      }
      setApplicant(value);
      setApplication(newApplication);
    }
  };

  const sortedOrders = useMemo(
    () => Object.entries(orders).sort((a, b) => (a[1].name > b[1].name ? 1 : -1)),
    [orders]
  );

  const filteredAgents = useMemo(
    () =>
      Object.entries(agents).filter(([id, agent]) => {
        return !appDoc.application.sponsorId || agent.sponsorId === appDoc.application.sponsorId;
      }),
    [agents, appDoc.application.sponsorId]
  );

  const sortedSeasons = useMemo(
    () => Object.entries(seasons).sort(([, a], [, b]) => a.name.localeCompare(b.name)),
    [seasons]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
      <DialogTitle>Application Details</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, enter the application's details.</DialogContentText>
        <Grid container spacing={2} padding={2}>
          {/* ----------- Sponsor ----------- */}
          <Grid item xs={12}>
            {!adminSponsorId && (
              <TextField
                name={"sponsorId"}
                label={"Sponsor"}
                select
                error={!!errors.sponsor}
                helperText={errors.sponsor}
                onChange={onInputComboChange}
                value={(appDoc && appDoc.application && appDoc.application.sponsorId) || ""}
                required
                fullWidth
                margin={"dense"}
              >
                <MenuItem value="" key="no_sponsor">
                  <Typography fontStyle={"italic"}>no sponsor selected</Typography>
                </MenuItem>
                {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                  return (
                    <MenuItem value={sponsorId} key={sponsorId}>
                      {sponsor.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Grid>

          {/* ----------- Agent ----------- */}
          <Grid item xs={12}>
            {!adminAgentId && (
              <TextField
                name={"agentId"}
                label={"Agent"}
                select
                error={!!errors.agent}
                helperText={errors.agent}
                onChange={onInputComboChange}
                value={(appDoc && appDoc.application && appDoc.application.agentId) || ""}
                fullWidth
                margin={"dense"}
              >
                <MenuItem value="" key="no_agent">
                  <Typography fontStyle={"italic"}>no agent selected</Typography>
                </MenuItem>
                {Object.values(filteredAgents).map(([agentId, agent], index) => {
                  return (
                    <MenuItem value={agentId} key={agentId}>
                      {agent.company}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
            {adminAgentId && (
              <Typography variant={"body1"}>Agent: {agents[adminAgentId]?.company}</Typography>
            )}
          </Grid>

          {/* ----------- Applicant ----------- */}
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Autocomplete
                id="applicant-combo-box"
                options={applicantOptions}
                getOptionKey={(option) => option.id}
                getOptionLabel={(option) => {
                  // e.g. value selected with enter, right from the input
                  if (option && typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.name;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (filtered.length === 0 && params.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      id: "new-applicant",
                      name: `Add new applicant "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                value={applicant}
                onChange={handleApplicantChange}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                isOptionEqualToValue={(option: ApplicantOptionType, value: ApplicantOptionType) => {
                  return option.id === value.id;
                }}
                renderOption={(props, option) => (
                  <li key={option.id} {...props}>
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Applicant"
                    variant="outlined"
                    margin={"dense"}
                    error={!!errors.applicant}
                    helperText={errors.applicant}
                    required
                  />
                )}
              />
              <AddApplicantDialog
                open={openAddApplicant}
                onClose={handleApplicantClose}
                onSave={handleSaveApplicant}
                applicant={editApplicant}
              />
            </Grid>
          </Grid>

          {/* ----------- Visa Type ----------- */}
          <Grid item xs={12} md={6}>
            <TextField
              name={"type"}
              label={"Visa type"}
              select
              error={!!errors.type}
              helperText={errors.type}
              onChange={onInputComboChange}
              value={(appDoc && appDoc.application && appDoc.application.type.key) || ""}
              required
              fullWidth
              margin={"dense"}
            >
              {Object.entries(APPLICATION_TYPES).map(([key, vtype]) => {
                return (
                  <MenuItem value={vtype.key} key={key}>
                    {vtype.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} />

          {/* ----------- Season ----------- */}

          <Grid item xs={12} md={6}>
            <TextField
              name={"seasonId"}
              label={"Season"}
              select
              error={!!errors.season}
              helperText={errors.season}
              onChange={onInputComboChange}
              value={(appDoc && appDoc.application.seasonId) || ""}
              required
              fullWidth
              margin={"dense"}
            >
              <MenuItem value="" key="no_season">
                <Typography fontStyle={"italic"}>no season selected</Typography>
              </MenuItem>
              {sortedSeasons.map(([seasonId, season]) => {
                return (
                  <MenuItem value={seasonId} key={seasonId}>
                    {season.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          {/* ----------- Work Orders ----------- */}
          <Grid item xs={12} md={6}>
            <TextField
              name={"workOrderId"}
              label={"Work Order"}
              error={!!errors.order}
              helperText={errors.order}
              select
              onChange={onInputComboChange}
              value={(appDoc && appDoc.application && appDoc.application.workOrderId) || ""}
              required
              fullWidth
              margin={"dense"}
            >
              <MenuItem value="" key="no_order">
                <Typography fontStyle={"italic"}>no order selected</Typography>
              </MenuItem>
              {sortedOrders.map(([orderId, order]) => {
                return (
                  <MenuItem value={orderId} key={orderId}>
                    {order.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            {appDoc && appDoc.application && appDoc.application.type.key === "seasonal" && (
              <TextField
                error={!!errors.cos}
                helperText={errors.cos}
                autoFocus
                margin="dense"
                name="cos"
                label="CoS number"
                type="text"
                fullWidth
                value={(appDoc && appDoc.application && appDoc.application.cos) || ""}
                onChange={handleInputChange}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              error={!!errors.gwf}
              helperText={errors.gwf}
              autoFocus
              margin="dense"
              name="gwf"
              label="GWF number"
              type="text"
              fullWidth
              value={(appDoc && appDoc.application && appDoc.application.gwf) || ""}
              onChange={handleInputChange}
            />
          </Grid>
          {!(appDoc && appDoc.application && appDoc.application.type.key === "seasonal") && (
            <Grid item xs={12} md={6}></Grid>
          )}

          <Grid item xs={12} md={6}>
            <TextField
              name="dataForm_expiry_date"
              label="Data form expiration date"
              error={!!errors.expiry_date}
              helperText={errors.expiry_date}
              type="date"
              value={
                (appDoc && appDoc.application && appDoc.application.dataForm_expiry_date) || ""
              }
              onChange={handleInputChange}
              margin={"dense"}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="virtualPresentation_expiry_date"
              label="Virtual presentation expiration date"
              error={!!errors.virtualform_expiry_date}
              helperText={errors.virtualform_expiry_date}
              type="date"
              value={
                (appDoc &&
                  appDoc.application &&
                  appDoc.application.virtualPresentation &&
                  appDoc.application.virtualPresentation.expiry_date) ||
                ""
              }
              onChange={handleVPInputChange}
              margin={"dense"}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplicationDialog;
