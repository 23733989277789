import { FormEvent, useContext, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  Container,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { auth } from "../../types/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { AuthContext } from "../../components/auth-provider";
import { useLocation, useNavigate } from "react-router-dom";
import { hasRole } from "../../utils/user-utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface LoginPageProps {
  mode?: "login" | "signup" | "finishsignup";
}

const LoginPage = ({ mode = "login" }: LoginPageProps) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const oobCode = urlParams.get("oobCode");

  const { currentUser, isLoading } = useContext(AuthContext)!;

  useEffect(() => {
    // If user is already logged in, redirect to home
    if (!isLoading && currentUser && currentUser.authUser) {
      console.log("redirecting to applicants");
      if (mode === "login") {
        if (currentUser.appUser && currentUser.appUser.role) {
          if (hasRole(currentUser.appUser, ["worker"])) {
            navigate("/myapplications", location);
          } else if (hasRole(currentUser.appUser, ["recruiter"])) {
            navigate("/candidates", location);
          } else navigate("/applications", location);
        } else navigate("/", location);
      }
    }
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(auth, username, password);
      // Signed in
      if (userCredential.user) {
        console.log("user signed in");
      } else {
        console.log("user could not be signed in");
      }

      // ...
    } catch (err: any) {
      setError("Could not log in! Wrong username or password");
      console.error(err.message);
      // Handle errors here
    }
  };

  const finishSignUp = async () => {
    if (password !== passwordConfirmation) {
      setError("Passwords do not match");
      return;
    }
    if (oobCode) {
    }
  };

  const getLoginContent = () => {
    return (
      <form onSubmit={handleLogin}>
        <Grid container direction="column" spacing={2} paddingX={3} paddingBottom={3}>
          <Grid item>
            <TextField
              type="email"
              placeholder="Email"
              fullWidth
              name="username"
              variant="outlined"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              error={!!error}
              helperText={error}
              margin="dense"
              required
              autoFocus
            />
          </Grid>
          <Grid item>
            <OutlinedInput
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              fullWidth
              name="password"
              margin="dense"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item>
            <Grid container direction={"row"} justifyContent={"flex-end"}>
              <Button variant="contained" color="primary" type="submit" className="button-block">
                Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  const getSignupContent = () => {
    return <div>Signup</div>;
  };

  const getFinishSignupContent = () => {
    return (
      <Grid container direction="column" spacing={2} paddingX={3} paddingBottom={3}>
        <Grid item>
          <TextField
            type="password"
            placeholder="Password"
            fullWidth
            name="password"
            variant="outlined"
            margin="dense"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required
          />
        </Grid>
        <Grid item>
          <TextField
            type="password"
            placeholder="Password confirmation"
            fullWidth
            name="password_confirmation"
            variant="outlined"
            margin="dense"
            value={passwordConfirmation}
            onChange={(event) => setPasswordConfirmation(event.target.value)}
            required
          />
        </Grid>
        <Grid item>
          <Grid container direction={"row"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="button-block"
              onClick={finishSignUp}
            >
              Save Password
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container maxWidth="xs" sx={{ marginTop: "3rem", marginBottom: "2rem" }}>
      <Grid container direction="column" justifyContent="center" spacing={2} className="login-form">
        <Paper variant="elevation" elevation={2} className="login-background">
          <Grid item padding={3}>
            <div>
              <Typography variant="h4" component="h1">
                <b>Welcome!</b>
              </Typography>
              <Typography variant="body2">Sign in to continue.</Typography>
            </div>
          </Grid>
          <Grid item>
            {mode === "login" && getLoginContent()}
            {mode === "signup" && getSignupContent()}
            {mode === "finishsignup" && getFinishSignupContent()}
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
};

export default LoginPage;
