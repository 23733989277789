import { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Card, CardActions, CardContent, CardHeader, Grid } from "@mui/material";
import { TableCell } from "@mui/material";
import { Person, PersonOff } from "@mui/icons-material";
// import { MuiColorInput, MuiColorInputColors, matchIsValidColor } from "mui-color-input";

// import EditIcon from "@mui/icons-material/Edit";
// import { ref, getDownloadURL, uploadString } from "firebase/storage";
// import { storage } from "../../types/firebase";
// import header_Image from "../../ressources/ibc_logo.png";
// import checked_background from "../../ressources/checked_background2.png";

import { isEMailValid } from "../../utils/utils";

import { TitledPage } from "../../components/titled-page";
import { AuthContext } from "../../components/auth-provider";
import { HeadCells, Order, getComparator } from "../../types/tables";
import { TableVirtuoso } from "react-virtuoso";
import { VirtuosoTableComponents } from "../../components/enhanced-virtuoso-table";
import { EnhancedTableHeadContents } from "../../components/enhanced-table";
import { isEqual } from "lodash";
import { AppUser, AppUserDoc, AppUsersDict } from "../../types/users";
import {
  disableUser,
  enableUser,
  loadAppUsers,
  storeAppUser,
  updateClaims,
} from "../../data-functions/users-api";
import { SponsorsDict } from "../../types/sponsors";
import { loadSponsors } from "../../data-functions/system-data_api";
import { loadAgents } from "../../data-functions/agent-api";
import { AgentsDict } from "../../types/agents";
import AddUserDialog from "../dialogs/dialogUserAdd";

const headCells: HeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sortable: true,
  },
  { id: "email", numeric: false, disablePadding: false, label: "Email", sortable: true },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "",
    align: "right",
    sortable: false,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    sortable: false,
    align: "right",
    width: "48px",
  },
];

const EMPTY_ERRORS = { name: "", surname: "", email: "", role: "", sponsorId: "", password: "" };

const PageUsers: React.FC = () => {
  const [users, setUsers] = useState<AppUsersDict>({});
  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [agents, setAgents] = useState<AgentsDict>({});

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [selectedUser, setSelectedUser] = useState<AppUserDoc | null>(null);
  // const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const [openAddNewUserDialog, setOpenAddNewUserDialog] = useState<boolean>(false);

  const { currentUser } = useContext(AuthContext)!;

  const [errors, setErrors] = useState<{
    name: string;
    surname: string;
    email: string;
    role: string;
    sponsorId: string;
    agentId?: string;
    password: string;
  }>({ ...EMPTY_ERRORS });

  // const fileInputRef = useRef<HTMLInputElement | null>(null);

  const fetchAppUsers = async () => {
    const usersDict: AppUsersDict = await loadAppUsers(currentUser?.appUser);
    setUsers(usersDict);
  };

  const fetchSponsors = async () => {
    const sponsorsDict: SponsorsDict = await loadSponsors(currentUser?.appUser);
    setSponsors(sponsorsDict);
  };

  const fetchAgents = async () => {
    const agentsDict = await loadAgents(currentUser?.appUser);
    if (agentsDict) {
      setAgents(agentsDict);
    } else {
      setAgents({});
    }
  };

  useEffect(() => {
    fetchSponsors();
    fetchAgents();
    fetchAppUsers();
  }, [currentUser]);

  const handleAddUser = async () => {
    setOpenAddNewUserDialog(true);
    // const appUser = { id: "", name: "", surname: "", email: "", role: [] };
    // setErrors({ ...EMPTY_ERRORS });
    // setSelectedUser({ id: "", user: appUser });
  };

  const handleAssignClaims = async () => {
    console.log("Assign claims");
    const response = await updateClaims();
    if (response.status === "OK") {
      window.alert("Claims assigned successfully");
    } else {
      console.log("response error: " + response.error);
      window.alert("Error assigning claims");
    }
  };

  const handleSaveUser = async () => {
    if (!(selectedUser && validate())) {
      return;
    }

    // Check if document exists
    if (selectedUser) {
      // upload the new image if there's any
      //   const imageURL = await handleUploadAndSaveLogo();
      //   if (imageURL) {
      //     selectedUser.user.logo = imageURL;
      //     setImageSrc(null);
      //   }

      // Update the document
      selectedUser.user.name.trim();
      selectedUser.user.surname.trim();
      selectedUser.user.email.trim();

      // https://accounts.google.com/speedbump/changepassword?TL=ALv_Gf-S67Z35pHyq0Er4P_taQ6JnDRyImdV4p5DPDj4NDJgr4e8FMIsfm8e3R9h&c=CLGPr43LmZL97QEQi_fAkYOH07mIAQ&continue=https%3A%2F%2Fworkspace.google.com%2Fdashboard&hl=en

      if (selectedUser.id === "") {
      }

      const newUser = await storeAppUser(currentUser?.appUser, selectedUser, true);

      if (newUser) {
        const newUsers = { ...users };
        newUsers[newUser.id] = { ...newUser.user };
        setUsers(newUsers);
        setSelectedUser(newUser);
      }
    } else {
      console.log("No user selected");
    }
  };

  const handleDeactivate = async (userId: string) => {
    if (!(userId && users[userId])) return;

    const user = users[userId];
    const msg = user.disabled ? "activate" : "deactivate";

    if (window.confirm(`Are you sure you want to ${msg} this user?`)) {
      let result = "sucess";
      if (user.disabled) {
        result = await enableUser(currentUser?.appUser, user.id);
      } else {
        result = await disableUser(currentUser?.appUser, user.id);
      }
      console.log("received result: " + result);

      switch (result) {
        case "success":
          const newUsers = { ...users };
          const newUser = { ...user, disabled: !user.disabled };
          newUsers[userId] = newUser;
          setUsers(newUsers);
          break;
        case "no-user":
          window.alert("The user does not exist");
          break;

        case "insufficient-permissions":
          window.alert("You do not have enough permissions to delete this user.");
          break;

        default:
          console.error("Unknown error");
      }
    }
  };

  const handleSelectUser = (userId: string) => {
    if (userId) {
      const user = users[userId];
      if (user) {
        if (selectedUser && selectedUser?.id !== userId) {
          const sEmp = selectedUser.id ? users[selectedUser.id] : null;

          if (!isEqual(sEmp, selectedUser.user)) {
            const conf = window.confirm(
              "The selected user has been changed! Press OK if you want to discard the changes."
            );
            if (!conf) {
              return;
            }
          }
        }
        console.log("selected user: " + userId);
        console.log(user);
        setSelectedUser({ id: userId, user: { ...user } });
        setPassword("");
        setConfirmPassword("");
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const newUser: AppUserDoc = {
        ...selectedUser,
        user: {
          ...selectedUser.user,
          [event.target.name]: event.target.value,
        },
      };

      setSelectedUser(newUser);
    }
  };

  const onInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const value = event.target.value;
      const newUser: AppUserDoc = {
        ...selectedUser,
      };
      switch (event.target.name) {
        case "sponsorId":
          newUser.user.sponsorId = value;
          break;

        case "agentId":
          newUser.user.agentId = value;
          break;

        default:
          break;
      }

      setSelectedUser(newUser);
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      switch (event.target.name) {
        case "password":
          setPassword(event.target.value);
          break;

        case "confirmPassword":
          setConfirmPassword(event.target.value);
          break;

        default:
          break;
      }
    }
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const role = event.target.name.split("-")[1];
      let newRoles = [...selectedUser.user.role];
      if (event.target.checked) {
        newRoles.push(role);
      } else {
        newRoles = newRoles.filter((r) => r !== role);
      }

      const newUser: AppUserDoc = {
        ...selectedUser,
        user: {
          ...selectedUser.user,
          role: newRoles,
        },
      };
      setSelectedUser(newUser);
    }
  };

  const validate = () => {
    const newErrors = { ...EMPTY_ERRORS };
    let isValid = true;
    if (selectedUser) {
      if (selectedUser.user.name.length < 3) {
        newErrors.name = "Please, enter a valid name for this user!";
        isValid = false;
      }

      if (selectedUser.user.surname.length < 3) {
        newErrors.surname = "Please, enter a valid surname for this user!";
        isValid = false;
      }

      if (!isEMailValid(selectedUser.user.email)) {
        newErrors.email = "Please, enter a valid email addres, for this user!";
        isValid = false;
      }

      if (selectedUser.user.role.length === 0) {
        newErrors.role = "Please, enter at least one role for this user!";
        isValid = false;
      }

      if (!selectedUser.user.sponsorId) {
        newErrors.sponsorId = "Please, enter a sponsor for this user!";
        isValid = false;
      }

      if (selectedUser.id === "" || password !== "") {
        if (password.length < 6) {
          newErrors.password = "Please, enter a password with at least 6 characters!";
          isValid = false;
        }
        if (password !== confirmPassword) {
          newErrors.password = "Passwords do not match!";
          isValid = false;
        }
      }
    }
    setErrors(newErrors);
    return isValid;
  };

  // const handleColorChange = (newValue: string, colors: MuiColorInputColors) => {
  //   if (matchIsValidColor(newValue) && selectedUser) {
  //     const newEmployer: Employer = {
  //       ...selectedUser,
  //       color: newValue,
  //     };
  //     setSelectedEmployer(newEmployer);
  //   } // boolean
  // };

  /*--------- Image Upload ----------- */
  //   const handleImageClick = () => {
  //     console.log("open image select");
  //     fileInputRef.current?.click();
  //     //setIsDialogOpen(true);
  //   };

  //   const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const file = event.target.files && event.target.files[0];
  //     if (file) {
  //       const reader = new FileReader();

  //       reader.onload = function (e: ProgressEvent<FileReader>) {
  //         if (e.target && e.target.result) {
  //           setImageSrc(e.target.result as string);
  //         }
  //       };

  //       reader.readAsDataURL(file);
  //     }
  //   };

  //   const handleUploadAndSaveLogo = async () => {
  //     if (imageSrc && selectedUser) {
  //       // Step 1: Check if the user already has an image URL and delete it
  //       // commented out as the uploadString will replace the old one. should we use more pictures
  //       // uncomment the deletion again.

  //       //   if (selectedUser.url) {
  //       //     const oldImageRef = ref(storage, "users/" + selectedUserId + ".jpg");
  //       //     try {
  //       //       await deleteObject(oldImageRef);
  //       //     } catch (error) {
  //       //       console.error("Failed to delete the old image:", error);
  //       //       return; // If the delete fails, we might not want to proceed with uploading the new image
  //       //     }
  //       //   }

  //       // Step 2: Upload the image to Firebase Storage
  //       const storageRef = ref(storage, "users/" + selectedUser.id + ".jpg");
  //       await uploadString(storageRef, imageSrc, "data_url");

  //       // Step 3: Get download URL
  //       const downloadURL: string = await getDownloadURL(storageRef);

  //       return downloadURL;
  //     } else {
  //       // console.error("Either the image source is not available or no user is selected.");
  //       return "";
  //     }
  //   };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const userFullName = (user: AppUser) => {
    return user.name + " " + user.surname;
  };

  /* ------------ Search functions -------------- */
  const filteredUsers = Object.entries(users).filter(([userId, user]) => {
    const sValue = searchValue.toLowerCase();
    return (
      searchValue.length < 3 ||
      userFullName(user).toLowerCase().includes(sValue) ||
      user.email?.toLowerCase().includes(sValue) ||
      user.role.some((r) => r.toLowerCase().includes(sValue)) ||
      (user.disabled && "disabled".includes(sValue)) ||
      (!user.disabled && "enabled".includes(sValue))
    );
  });

  const visibleRows = useMemo(
    () => filteredUsers.slice().sort(getComparator(order, orderBy as keyof AppUser)),
    [order, orderBy, filteredUsers]
  );

  const filteredAgents = useMemo(
    () =>
      Object.entries(agents).filter(([id, agent]) => {
        return (
          selectedUser &&
          selectedUser.user.sponsorId !== "" &&
          agent.sponsorId === selectedUser.user.sponsorId
        );
      }),
    [selectedUser, agents]
  );

  return (
    <TitledPage title="Application Users">
      <Box>
        <Box display={"flex"} sx={{ width: "50%", gap: 2 }}>
          <AddUserDialog
            open={openAddNewUserDialog}
            onClose={() => {
              setOpenAddNewUserDialog(false);
            }}
          />
          <Button variant="outlined" color="primary" size="small" onClick={handleAddUser}>
            Add New user
          </Button>
          {/* <Button variant="outlined" color="primary" size="small" onClick={handleAssignClaims}>
            Assign claims
          </Button> */}

          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{ minWidth: "300px" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1rem",
            minHeight: "400px",
            height: "calc(100vh - 210px)",
          }}
        >
          <Box sx={{ width: "70%", marginRight: "2%" }}>
            <TableVirtuoso
              style={{ height: "100%" }}
              data={visibleRows}
              context={{ selectedId: selectedUser?.id, handleSelect: handleSelectUser }}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() => (
                <EnhancedTableHeadContents
                  numSelected={selectedUser?.id ? 1 : 0}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={() => {}}
                  onRequestSort={handleRequestSort}
                  rowCount={visibleRows.length}
                  headCells={headCells}
                />
              )}
              itemContent={(index, [userId, user]) => {
                const isDisabled = user.disabled;
                return (
                  <>
                    <TableCell
                      scope="row"
                      size="small"
                      sx={{
                        fontStyle: isDisabled ? "italic" : "normal",
                        color: isDisabled ? "grey" : "black",
                      }}
                    >
                      {userFullName(user)}
                    </TableCell>
                    <TableCell
                      scope="row"
                      size="small"
                      sx={{
                        fontStyle: isDisabled ? "italic" : "normal",
                        color: isDisabled ? "grey" : "black",
                      }}
                    >
                      {user.email}
                    </TableCell>
                    <TableCell scope="row" size="small" align="right">
                      {isDisabled && <Chip label="Disabled" variant="outlined" />}
                    </TableCell>
                    <TableCell align="right" style={{ width: 120 }} size="small">
                      <Tooltip title={isDisabled ? "Activate account" : "Deactivate account"}>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeactivate(userId);
                          }}
                        >
                          {isDisabled ? <Person /> : <PersonOff />}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </>
                );
              }}
            />
          </Box>

          {/* ------------- Employer Details ------------- */}
          <Box sx={{ width: "48%" }}>
            {selectedUser && (
              <Card>
                <CardHeader title={"User Details"} />
                <CardContent>
                  <Grid container spacing={2} padding={2}>
                    {/* Image uploading will be implemented later */}
                    {/* <Grid item xs={12}>
                      <Box
                        position="relative"
                        display="inline-block"
                        sx={{
                          backgroundImage: `url(${checked_background})`,
                          backgroundRepeat: "repeat",
                        }}
                      >
                        <img
                          src={imageSrc || selectedUser?.user.logo || header_Image}
                          onClick={handleImageClick}
                          alt="Employer"
                          style={{ maxWidth: "350px", cursor: "pointer" }}
                        />
                        <Box
                          position="absolute"
                          bottom={0}
                          width="100%"
                          height="50px"
                          bgcolor="rgba(0, 0, 0, 0.5)"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          gap="10px"
                          sx={{ cursor: "pointer" }}
                          onClick={handleImageClick}
                        >
                          <EditIcon sx={{ color: "white" }} />
                          <Typography variant="body2" color="white">
                            Click to change the logo
                          </Typography>
                        </Box>
                      </Box>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        ref={fileInputRef}
                      />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={!!errors.name}
                        helperText={errors.name}
                        required
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={selectedUser && selectedUser.user.name}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={!!errors.surname}
                        helperText={errors.surname}
                        required
                        margin="dense"
                        name="surname"
                        label="Family name"
                        type="text"
                        fullWidth
                        value={selectedUser && selectedUser.user.surname}
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        error={!!errors.email}
                        helperText={errors.email}
                        required
                        margin="dense"
                        name="email"
                        label="Email"
                        type="text"
                        fullWidth
                        value={(selectedUser && selectedUser.user.email) || ""}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    {selectedUser && selectedUser.id === "" && (
                      <>
                        <Grid item xs={12} md={6}>
                          <TextField
                            error={!!errors.password}
                            helperText={errors.password}
                            autoFocus
                            margin="dense"
                            name="pasword"
                            label="Password"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={handlePasswordChange}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            autoFocus
                            margin="dense"
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={handlePasswordChange}
                          />
                        </Grid>
                      </>
                    )}
                    {/* <Grid item xs={12}>
                      <Button variant="outlined">Reset Password</Button>
                    </Grid> */}
                    {/* ----------- Sponsors ----------- */}
                    {!currentUser?.appUser?.sponsorId && (
                      <Grid item xs={12}>
                        <TextField
                          name={"sponsorId"}
                          label={"Sponsor"}
                          select
                          error={!!errors.sponsorId}
                          helperText={errors.sponsorId}
                          onChange={onInputComboChange}
                          value={(selectedUser && selectedUser.user.sponsorId) || ""}
                          required
                          fullWidth
                          margin={"dense"}
                        >
                          <MenuItem value="" key="no_sponsor">
                            <Typography fontStyle={"italic"}>no sponsor selected</Typography>
                          </MenuItem>
                          {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                            return (
                              <MenuItem value={sponsorId} key={sponsorId}>
                                {sponsor.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    )}

                    {/* ----------- Agent ----------- */}
                    <Grid item xs={12}>
                      {!currentUser?.appUser?.agentId && (
                        <TextField
                          name={"agentId"}
                          label={"Agent"}
                          select
                          error={!!errors.agentId}
                          helperText={errors.agentId}
                          onChange={onInputComboChange}
                          value={(selectedUser && selectedUser.user.agentId) || ""}
                          disabled={filteredAgents.length === 0}
                          required
                          fullWidth
                          margin={"dense"}
                        >
                          <MenuItem value="" key="no_agent">
                            <Typography fontStyle={"italic"}>no agent selected</Typography>
                          </MenuItem>
                          {Object.values(filteredAgents).map(([agentId, agent], index) => {
                            return (
                              <MenuItem value={agentId} key={agentId}>
                                {agent.company}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      )}
                      {currentUser?.appUser?.agentId && (
                        <Typography variant={"body1"}>
                          Agent: {agents[currentUser.appUser.agentId].company}
                        </Typography>
                      )}
                    </Grid>

                    {/* Roles */}
                    <Grid item xs={12}>
                      <FormLabel component="legend" sx={{ mt: 3 }}>
                        Roles
                      </FormLabel>
                      <FormControl component="fieldset" variant="standard" sx={{ mr: 3 }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedUser.user.role.includes("admin")}
                                onChange={handleRoleChange}
                                name="role-admin"
                              />
                            }
                            label="Administrator"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={selectedUser.user.role.includes("admin")}
                                checked={selectedUser.user.role.includes("recruiter")}
                                onChange={handleRoleChange}
                                name="role-recruiter"
                              />
                            }
                            label="Recruiter"
                          />
                        </FormGroup>
                      </FormControl>
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={selectedUser.user.role.includes("admin")}
                                checked={selectedUser.user.role.includes("operator")}
                                onChange={handleRoleChange}
                                name="role-operator"
                              />
                            }
                            label="Visa Operator"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={selectedUser.user.role.includes("admin")}
                                checked={selectedUser.user.role.includes("cos_operator")}
                                onChange={handleRoleChange}
                                name="role-cos_operator"
                              />
                            }
                            label="CoS Operator"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ padding: 2 }}>
                  <Box flexGrow={1}></Box>
                  <Button variant="contained" onClick={handleSaveUser} sx={{ mr: 2 }}>
                    Save
                  </Button>
                </CardActions>
              </Card>
            )}
          </Box>
        </Box>
      </Box>
    </TitledPage>
  );
};

export default PageUsers;
