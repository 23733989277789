import { Applicant, ApplicantDoc, ApplicantsDict } from "../types/applicants";
import { AppUser } from "../types/users";
import {
  CollectionReference,
  Query,
  addDoc,
  collection,
  deleteDoc,
  doc,
  documentId,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import db from "../types/firebase";

export const existingApplicants = async (user: AppUser | null | undefined, emails: string[]) => {
  let duplicates: string[] = [];

  // Function to chunk the array
  const chunkArray = (myArray: string[], chunk_size: number) => {
    let index = 0;
    let arrayLength = myArray.length;
    let tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      let chunk = myArray.slice(index, index + chunk_size);
      tempArray.push(chunk);
    }

    return tempArray;
  };

  // Chunk the emails array into subarrays of 25
  const emailChunks = chunkArray(emails, 25);

  for (const emailChunk of emailChunks) {
    let q;
    console.log("querying for emails: ", emailChunk);
    if (user?.sponsorId) {
      q = query(
        collection(db, "applicants"),
        where("email", "in", emailChunk),
        where("sponsorId", "==", user.sponsorId)
      );
    } else {
      q = query(collection(db, "applicants"), where("email", "in", emailChunk));
    }
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      duplicates.push(doc.data().email.toLowerCase());
    });
  }

  console.log("found duplicates: ", duplicates);

  return duplicates;
};

export const loadApplicant = async (applicantId: string) => {
  let loadedApplicant = null;

  if (applicantId) {
    const docRef = doc(db, "applicants", applicantId);
    const applicantSnapshot = await getDoc(docRef);
    if (applicantSnapshot) {
      loadedApplicant = {
        id: applicantSnapshot.id,
        applicant: applicantSnapshot.data() as Applicant,
      };
    }
  }
  return loadedApplicant;
};

export const loadApplicants = async (
  user: AppUser | null | undefined,
  filter: Record<string, string> = {},
  cLimit: number = 0
) => {
  let applicantsRef: Query | CollectionReference;
  const sponsorId = user && user.sponsorId ? user.sponsorId : "";
  const agentId = user && user.agentId ? user.agentId : "";

  if (agentId !== "") {
    applicantsRef = query(collection(db, "applicants"), where("agentId", "==", agentId));
  } else if (sponsorId !== "") {
    applicantsRef = query(collection(db, "applicants"), where("sponsorId", "==", sponsorId));
  } else {
    applicantsRef = collection(db, "applicants");
  }

  Object.keys(filter).forEach((key) => {
    applicantsRef = query(applicantsRef, where(key, "==", filter[key]));
  });
  if (cLimit > 0) applicantsRef = query(applicantsRef, limit(cLimit));

  const applicantsSnapshot = await getDocs(applicantsRef);
  const applicantsDict: ApplicantsDict = {};

  applicantsSnapshot.forEach((doc) => {
    const applicant = doc.data() as Applicant;
    applicantsDict[doc.id] = applicant;
  });

  return applicantsDict;
};

export const storeApplicant = async (
  user: AppUser | null | undefined,
  applicantDoc: ApplicantDoc | null,
  addIfNew: boolean
) => {
  if (!(applicantDoc && applicantDoc.applicant)) {
    return null;
  }

  let applicantSnapshot = null;
  applicantDoc.applicant.name = applicantDoc.applicant.name.trim();
  applicantDoc.applicant.surname = applicantDoc.applicant.surname.trim();
  if (applicantDoc.id) {
    const docRef = doc(db, "applicants", applicantDoc.id);
    applicantSnapshot = await getDoc(docRef);
    // Update the document
    if (applicantSnapshot) {
      applicantDoc.applicant.updatedAt = new Date().toISOString();
      applicantDoc.applicant.updatedBy = user ? user.id : "anonymous";
      await setDoc(docRef, applicantDoc.applicant);
      console.log("updated applicant:", applicantDoc.id);
    } else {
      console.log(`No document found with id: ${applicantDoc.id}`);
    }
  } else if (addIfNew) {
    console.log("adding new applicant");
    applicantDoc.applicant.createdAt = new Date().toISOString();
    applicantDoc.applicant.createdBy = user ? user.id : "anonymous";
    applicantDoc.id = (await addDoc(collection(db, "applicants"), applicantDoc.applicant)).id;
  } else {
    return null;
  }

  return applicantDoc.id;
};

export const deleteApplicant = async (applicantId: string) => {
  if (!applicantId) return;

  return deleteDoc(doc(db, "applicants", applicantId));
};

export const findApplicantByIdAndEmail = async (applicantId: string, email: string) => {
  //TODO: excahnge this with a server function

  if (!applicantId || !email) {
    return null;
  }

  // Create a query to find the applicant with the specified ID and email
  const applicant = await loadApplicant(applicantId);

  // Check if any documents were found
  if (applicant && applicant.applicant.email?.toLocaleLowerCase() === email.toLocaleLowerCase()) {
    return applicant;
  }

  return null;
};
