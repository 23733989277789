export const APPLICATION_MAPPING: Record<string, string> = {
  employer: "Farm",
  order: "Order No.",
  order_start_date: "Order Start Date",
};

export const CSV_APPLICANT_MAPPING: Record<string, string> = {
  Name: "name",
  "Last Name": "surname",
  "If you have a maiden name, please give detail": "maiden_name",
  "Phone number": "phone",
  Email: "email",
  Gender: "gender",
  "Have Additional nationality": "",
  "have a National Insurance Number UK": "UK_insurance",
  "Have you ever visited a doctor in UK": "visited_UK_doctor",
  "Current passport or travel document number": "passport.id",
  "marital status": "marital_status",
  "have children": "has_children",
  children: "children",
  "Place of Birth": "birth_place",
  "have relatives in UK": "has_relatives_in_UK",
  "relatives in the UK": "relatives_UK",
  "Permanent residential address": "residency_street",
  "City/Town/Village": "residency_city",
  "Area/Region": "residency_province",
  "Post Code": "residency_zip",
  "Property Ownership": "residency_ownership",
  "How long have you lived at this address?": "residency_date_since",
  "Next of Kin": "immergency_contact",
  "Have you ever been granted a UK visa?": "granted_UK_visa",
  "Have you ever travelled to the UK before?": "has_travelled_to_UK",
  "UK travell details  - date of entry and for how long": "travel_details_UK",
  "worked illegally in UK": "has_worked_illegally",
  "required to leave the UK": "required_to_leave_UK",
  "refused visa": "refused_visa",
  "refused entry on arrival": "refused_entry",
  "application to the Home Office to remain in the UK": "has_applied_for_visa",
  "travellin the last 10 years": "has_travelled_abroad",
  "refused a visa for any country": "refused_visa_abroad",
  "deported from another country": "deported_abroad",
  "deportation details": "deported_abroad_details",
  "criminal convictions": "convictions",
  "involvement in war crime": "war_crimes",
  "involved in terrosist organisations": "terrorist_org_member",
  "not considered a person of good character": "not_good_character",
  "additional information considered in your application": "application_additional_information",
  "Will children be travelling with you": "children_travelling",
  "Father's given name": "father_name",
  "Father's family name": "father_surname",
  "Father's place and country of birth": "father_birth_place",
  "Mother's given name": "mother_name",
  "Mother's family name": "mother_surname",
  "Mother's place of birth": "mother_birth_place",
  "Please provide details about your working experience": "work_experience",
  "driving license": "has_driving_license",
  "English level": "english_level",
  "health issues": "has_health_issues",
  "application status": "application_status",
};

export const CSV_MAPPING_COUNTRY_PROPERTIES: Record<string, string> = {
  Nationality: "nationality",
  "Additional nationality": "additional_nationality",
  "Place of issue. Место выдачи.": "passport.issuing_country",
  "Country of Birth": "birth_country",
  "Country of Residence": "residency_country",
  "Father's country of birth": "father.birthCountry",
  "Father's nationality": "father.nationality",
  "Mother's country of birth": "mother.birthCountry",
  "Mother's nationality": "mother.nationality",
};

export const CSV_MAPPING_DATE_PROPERTIES: Record<string, string> = {
  "Date of birth": "birth_date",
  "Date of issue": "passport.issue_date",
  "Date of expiry": "passport.expiry_date",
  "Father's date of birth": "father.birthday",
  "Mother's date of birth": "mother.birthday",
};

export const parseCSVDate = (date: string) => {
  const [day, month, year] = date.split(".").map(Number);
  const fullYear = year < 50 ? 2000 + year : 1900 + year;

  return new Date(fullYear, month - 1, day);
};

export const parseCountry = (country: string) => {
  return {
    code: country,
    name: "",
  };
};
