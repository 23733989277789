import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SponsorDoc } from "../../types/sponsors";
import { messages, SupportedLanguages } from "../../languages/languages";
import {
  loadCampaign,
  loadCandidateByVerificationCode,
  loadCandidates,
  saveCandidate,
} from "../../data-functions/recruiting_api";
import { loadSponsor } from "../../data-functions/system-data_api";
import { FormattedMessage, IntlProvider } from "react-intl";
import { Box, Button, Card, Container, Typography } from "@mui/material";
import { sendEmailVerificationEmail } from "../../utils/candidate-utils";
import { generatePassword } from "../../utils/utils";
import { CampaignDoc, CampaignsDict, CandidateDoc, CandidatesDict } from "../../types/recruiting";
import gwLogo from "../../ressources/global workforce_thin font.png";

type ParamTypes = {
  cid: string;
};

const ACTIVE_STEP_EMPTY = -1;
const ACTIVE_STEP_REGISTERED = 0;
const ACTIVE_STEP_REGISTRATION_ALREADY_CONFIRMED = 2;
const ACTIVE_STEP_EXPIRED_CANDIDATE = 3;
const ACTIVE_STEP_INVALID_CANDIDATE = 4;

export const VerifyRegistrationPage = () => {
  const { cid } = useParams<ParamTypes>();
  const [sponsor, setSponsor] = useState<SponsorDoc | null>(null);
  const [language, setLanguage] = useState<SupportedLanguages>("en");

  const [candidate, setCandidate] = useState<CandidateDoc | null>(null);
  const [campaign, setCampaign] = useState<CampaignDoc | null>(null);

  const [activeStep, setActiveStep] = useState<number>(ACTIVE_STEP_EMPTY);

  useEffect(() => {
    const fetchSponsor = async () => {
      if (cid) {
        const candidateDoc = await loadCandidateByVerificationCode(cid);
        if (!candidateDoc) {
          setCandidate(null);
          console.error("Candidate ID: " + cid + " was not found");
          setActiveStep(ACTIVE_STEP_INVALID_CANDIDATE);
          return;
        }

        const sponsor = await loadSponsor(candidateDoc.candidate.sponsorId);
        const campaignDoc = await loadCampaign(candidateDoc.candidate.campaignId || "");
        const newCampaign = campaignDoc?.campaign || null;

        setCandidate(candidateDoc);
        setCampaign(campaignDoc);

        if (sponsor) {
          setSponsor(sponsor);
        }
        if (newCampaign) {
          if (newCampaign?.language && newCampaign.language !== language) {
            setLanguage(newCampaign.language as SupportedLanguages);
          }
        }

        if (candidateDoc.candidate.verification) {
          if (candidateDoc.candidate.verification.verifiedOn) {
            setActiveStep(ACTIVE_STEP_REGISTRATION_ALREADY_CONFIRMED);
          } else {
            candidateDoc.candidate.verification.verifiedOn = new Date().toISOString();
            candidateDoc.candidate.verification.confirmationCode = await generateConfirmationCode();
            candidateDoc.candidate.status = "verified";

            await saveCandidate(null, candidateDoc, false);
            resendConfirmationMail(candidateDoc);
            setActiveStep(ACTIVE_STEP_REGISTERED);
          }
        } else {
          setActiveStep(ACTIVE_STEP_INVALID_CANDIDATE);
        }
      } else {
        console.error("Candidate ID: " + cid + " was not found");
        setActiveStep(ACTIVE_STEP_EXPIRED_CANDIDATE);
      }
    };

    fetchSponsor();
    console.log("verify regisgtration for candidate: " + cid);
  }, [cid]);

  const resendConfirmationMail = async (candidateDoc: CandidateDoc | null) => {
    if (candidateDoc) {
      sendEmailVerificationEmail([candidateDoc.id], "candidate_confirmation");
    }
  };

  const generateConfirmationCode = async () => {
    let code = "";
    const campaigns: CampaignsDict = {};
    if (campaign) campaigns[campaign.id] = campaign?.campaign;

    let candidatesDict: CandidatesDict = {};
    do {
      code = generatePassword(6, false, false).toUpperCase();
      candidatesDict = await loadCandidates(null, campaigns, {
        "verification.confirmationCode": { value: code, operator: "==" },
      });
    } while (Object.keys(candidatesDict).length > 0);

    return code;
  };

  const getRegisteredCandidate = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_registration_confirmed_title"
            defaultMessage="Congratulations! Your registration has been confirmed!"
          />
        </Typography>
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_registration_confirmed_email"
            defaultMessage="You will receive an email with the confirmation. Please bring the email with you to the interview!"
          />
        </Typography>
      </Box>
    );
  };

  const getRegistrationAlreadyConfirmed = () => {
    return (
      <Box
        padding={"3rem"}
        paddingTop={"1rem"}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
      >
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_regitration_already_confirmed_title"
            defaultMessage="Your registration has been already confirmed!"
          />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            resendConfirmationMail(candidate);
          }}
        >
          <FormattedMessage
            id="register_resend_confirmation_button"
            defaultMessage="Resend Confirmation Mail"
          />
        </Button>
      </Box>
    );
  };

  const getInvalidCandidate = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_candidate_invalid_title"
            defaultMessage="The confirmation link you were provided is invalid!"
          />
        </Typography>
      </Box>
    );
  };

  const getExpiredCandidate = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_confirmation_expired_title"
            defaultMessage="This link has already expired! You need to make a new registration!"
          />
        </Typography>
      </Box>
    );
  };

  const getEmpty = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage id="register_empty_title" defaultMessage="Seasonal work in the UK" />
        </Typography>
      </Box>
    );
  };

  const getActivePage = () => {
    switch (activeStep) {
      case ACTIVE_STEP_REGISTERED:
        return getRegisteredCandidate();
      case ACTIVE_STEP_REGISTRATION_ALREADY_CONFIRMED:
        return getRegistrationAlreadyConfirmed();
      case ACTIVE_STEP_INVALID_CANDIDATE:
        return getInvalidCandidate();
      case ACTIVE_STEP_EXPIRED_CANDIDATE:
        return getExpiredCandidate();
      case ACTIVE_STEP_EMPTY:
        return getEmpty();
      default:
        return getEmpty();
    }
  };

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            src={sponsor ? sponsor.sponsor.logo : gwLogo}
            alt="logo"
            style={{ maxHeight: "200px", width: "auto" }}
          />
        </Box>
        <Card sx={{ marginTop: "1rem", padding: "1rem" }}>{getActivePage()}</Card>
      </Container>
    </IntlProvider>
  );
};
