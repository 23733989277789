import { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { Card, CardActions, CardContent, CardHeader, Grid } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import {
  Delete as DeleteIcon,
  Link as SendIcon,
  InfoOutlined as InfoIcon,
} from "@mui/icons-material";

import { SponsorsDict } from "../../types/sponsors";

import { TitledPage } from "../../components/titled-page";
import { CampaignDoc, CampaignsDict } from "../../types/recruiting";
import SimpleLinkDialog from "../dialogs/dialogSimpleLink";
import { AuthContext } from "../../components/auth-provider";
import {
  deleteCampaign,
  loadCampaigns,
  loadCandidates,
  saveCampaign,
} from "../../data-functions/recruiting_api";
import { loadSponsors } from "../../data-functions/system-data_api";
import { AgentDoc, AgentsDict } from "../../types/agents";
import { loadAgents } from "../../data-functions/agent-api";
import { isEqual } from "lodash";
import { LanguageSwitcher } from "../../components/language-switcher";
import { SupportedLanguages } from "../../languages/languages";
import { CustomSwitch } from "../../components/custom-switch";
import { DocumentHistoryDialog } from "../dialogs/dialogDocumentHistory";
import { hasRole } from "../../utils/user-utils";

const RecruitingCampaigns: React.FC = () => {
  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [campaigns, setCampaigns] = useState<CampaignsDict>({});
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignDoc | null>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [currentLink, setCurrentLink] = useState("");

  const [adminSponsorId, setAdminSponsorId] = useState<string>("");
  const [agents, setAgents] = useState<AgentsDict>({});
  const [userAgent, setUserAgent] = useState<AgentDoc | null>(null);

  const [searchValue, setSearchValue] = useState<string>("");
  const [showOpen, setShowOpen] = useState<boolean>(true);

  const [openHistory, setOpenHistory] = useState(false);
  const [historyDoc, setHistoryDoc] = useState<string>("");

  const [errors, setErrors] = useState<{
    name: string;
    sponsor: string;
    agent: string;
  }>({ name: "", sponsor: "", agent: "" });

  const { currentUser } = useContext(AuthContext)!;

  const fetchSponsors = async () => {
    const sponsorsDict: SponsorsDict = await loadSponsors(currentUser?.appUser);
    setSponsors(sponsorsDict);
  };

  const fetchCampaigns = async () => {
    const campaignsDict: CampaignsDict = await loadCampaigns(currentUser?.appUser);
    setCampaigns(campaignsDict);
  };

  const fetchAgents = async () => {
    const agentsDict = await loadAgents(currentUser?.appUser);
    const agentId = currentUser?.appUser?.agentId || "";
    if (agentId && agentsDict) {
      console.log("user agent: " + agentId, agentsDict[agentId]);
      setUserAgent({ id: agentId, agent: agentsDict[agentId] });
    } else setUserAgent(null);
    setAgents(agentsDict);
  };

  useEffect(() => {
    fetchSponsors();
    fetchCampaigns();
    fetchAgents();
    const sponsorId = currentUser?.appUser?.sponsorId || "";
    setAdminSponsorId(sponsorId);

    setSelectedCampaign(null);
  }, [currentUser]);

  const handleAddCampaign = async () => {
    const agentId = userAgent?.id || "";
    // if (!agentId) {
    //   window.alert("Campaigns can only be created by users assigned to an agent!");
    //   return;
    // }

    const sponsorId = adminSponsorId || userAgent?.agent?.sponsorId || "";
    const campaign = { name: "New Campaign", sponsorId, agentId, status: "open", language: "en" };

    setSelectedCampaign({ id: "", campaign });
  };

  const handleSaveCampaign = async () => {
    if (!(selectedCampaign && validate())) {
      return;
    }

    const newCampaign = await saveCampaign(currentUser?.appUser, selectedCampaign, true);

    // Check if document exists
    if (newCampaign) {
      const newCampaigns = { ...campaigns };
      newCampaigns[newCampaign.id] = { ...newCampaign.campaign };
      setCampaigns(newCampaigns);
      setSelectedCampaign(newCampaign);
    } else {
      console.log(`No document found with email: ${selectedCampaign?.campaign?.name}`);
    }
  };

  const handleDelete = async (campaignId: string) => {
    if (!campaignId) return;

    const newCampaign: CampaignsDict = {};
    newCampaign[campaignId] = campaigns[campaignId];

    const candidates = await loadCandidates(currentUser?.appUser, newCampaign, {}, 3);
    if (Object.keys(candidates).length > 0) {
      window.alert(
        "This camapaign has candidates assosiated with it and cannot be deleted! Try setting its status to Closed instead."
      );
      return;
    }

    if (
      window.confirm(
        "Are you sure you want to delete this campaign? All candidates associated with the campaign will also be deleted!"
      )
    ) {
      deleteCampaign(campaignId).then(() => {
        const newCampaigns = { ...campaigns };
        delete newCampaigns[campaignId];
        setSelectedCampaign(null);
        setCampaigns(newCampaigns);
      });
    }
  };

  const handleSendLink = async (campaignId: string) => {
    if (!campaignId) return;

    setCurrentLink("https://visauk.realizon.tech/register/" + campaignId);
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    //    setEditMode(false);
    setOpenAlert(false);
  };

  const handleShowHistory = async (documentId: string) => {
    setHistoryDoc(documentId);
    setOpenHistory(true);
  };

  const handleSelectCampaign = (campaignId: string) => {
    if (campaignId) {
      const campaign = campaigns[campaignId];
      if (campaign) {
        if (selectedCampaign && selectedCampaign.id !== campaignId) {
          const sCampaign = selectedCampaign.id ? campaigns[selectedCampaign.id] : null;

          if (!isEqual(sCampaign, selectedCampaign.campaign)) {
            const conf = window.confirm(
              "The selected campaign has been changed! Press OK if you want to discard the changes."
            );
            if (!conf) {
              return;
            }
          }
        }

        setSelectedCampaign({ id: campaignId, campaign: { ...campaign } });
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //    if (event.target.name === "expiry_date") {
    if (selectedCampaign) {
      const newCampaign: CampaignDoc = {
        ...selectedCampaign,
        campaign: {
          ...selectedCampaign.campaign,
          [event.target.name]: event.target.value,
        },
      };
      setSelectedCampaign(newCampaign);
    }
  };

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    const key = (event.target as Element).id;

    if (key && selectedCampaign) {
      const newCampaign: CampaignDoc = {
        ...selectedCampaign,
        campaign: {
          ...selectedCampaign.campaign,
          status: newValue,
        },
      };
      console.log(key + ": " + newValue);
      setSelectedCampaign(newCampaign);
    }
  };

  const handleInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedCampaign) {
      const value = event.target.value;

      const newCampaign: CampaignDoc = {
        ...selectedCampaign,
      };
      switch (event.target.name) {
        case "sponsorId":
          newCampaign.campaign.sponsorId = value;
          break;

        case "agentId":
          newCampaign.campaign.agentId = value;
          break;

        default:
          break;
      }

      setSelectedCampaign(newCampaign);
    }
  };

  const handleLanguageChange = (newLanguage: string | null) => {
    if (newLanguage && selectedCampaign) {
      const newCampaign: CampaignDoc = {
        ...selectedCampaign,
      };
      newCampaign.campaign.language = newLanguage;
      setSelectedCampaign(newCampaign);
    }
  };

  const validate = () => {
    const newErrors = { name: "", sponsor: "", agent: "" };
    let isValid = true;
    if (!selectedCampaign) return false;

    if (!selectedCampaign.campaign.name) {
      newErrors.name = "Please, enter a valid name for this campaign!";
      isValid = false;
    }

    if (!selectedCampaign.campaign.sponsorId) {
      newErrors.sponsor = "Please, select a sponsor forthis campaign!";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const getCampaignStatusChip = (status: string | undefined) => {
    switch (status) {
      case "open":
        return <Chip label="Open" color="success" />;

      case "closed":
        return <Chip label="Closed" color="error" />;

      default:
        return <Chip label="New" color="primary" variant="outlined" />;
    }
  };

  /* ------------ Search functions -------------- */

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const filteredCampaigns = useMemo(
    () =>
      Object.entries(campaigns).filter(([campaignId, campaign]) => {
        try {
          return (
            (!showOpen || campaign.status === "open") &&
            (searchValue.length < 3 ||
              campaign.name.toLowerCase().includes(searchValue.toLowerCase()) ||
              (campaign.status &&
                campaign.status.toLowerCase().includes(searchValue.toLowerCase())))
          );
        } catch (err) {
          console.error("filter exception:");
          console.log(campaign);
          return false;
        }
      }),
    [searchValue, showOpen, campaigns]
  );

  return (
    <TitledPage title="Recruiting Campaigns">
      <Box>
        <Box display={"flex"} sx={{ gap: 2 }}>
          <Button variant="outlined" color="primary" onClick={handleAddCampaign}>
            Add New Campaign
          </Button>
          {hasRole(currentUser?.appUser, ["admin"]) && (
            <>
              <DocumentHistoryDialog
                open={openHistory}
                onClose={() => setOpenHistory(false)}
                documentId={historyDoc}
              />
              <Tooltip title="History">
                <span>
                  <IconButton
                    color="primary"
                    edge={"end"}
                    sx={{ marginTop: "8px", marginBottom: "4px" }}
                    disabled={!selectedCampaign}
                    onClick={() => handleShowHistory(selectedCampaign?.id || "")}
                  >
                    <InfoIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ marginTop: "8px", marginBottom: "4px" }}
              />
            </>
          )}
          <Tooltip title={showOpen ? "Show only open campaigns" : "Show all campaigns"}>
            <CustomSwitch checked={showOpen} onClick={() => setShowOpen(!showOpen)} />
          </Tooltip>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={handleSearchValueChange}
            sx={{ minWidth: "250px" }}
          />
          <SimpleLinkDialog
            label={currentLink}
            open={openAlert}
            showQRCode={true}
            showSendLink={false}
            onSendLink={() => {}}
            onClose={handleAlertClose}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
          <Box
            sx={{ flexGrow: 1, flexBasis: "0", maxWidth: `calc(100% - 450px)`, marginRight: "8px" }}
          >
            <TableContainer component={Paper} sx={{ minHeight: "300px" }}>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th">Name</TableCell>
                    <TableCell component="th">Status</TableCell>
                    <TableCell component="th" align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(filteredCampaigns).map(([campaignId, campaign]) => {
                    return (
                      <TableRow
                        key={campaignId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onClick={() => handleSelectCampaign(campaignId)}
                        className={selectedCampaign?.id === campaignId ? "selected" : ""}
                      >
                        <TableCell scope="row" size="small">
                          {campaign.name}
                        </TableCell>
                        <TableCell scope="row" size="small">
                          {getCampaignStatusChip(campaign.status)}
                        </TableCell>
                        <TableCell align="right" style={{ width: 100 }} size="small">
                          <Tooltip title="Send Link">
                            <IconButton
                              size="small"
                              onClick={() => handleSendLink(campaignId || "")}
                            >
                              <SendIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton size="small" onClick={() => handleDelete(campaignId || "")}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* -------- Campaign Details -------- */}
          <Box sx={{ width: "48%", maxWidth: "450px" }}>
            {selectedCampaign && (
              <Card>
                <CardHeader title={"Campaign Details"} />
                <CardContent>
                  <Grid container spacing={2} padding={2}>
                    {/* ----------- Sponsors ----------- */}
                    <Grid item xs={12}>
                      {!adminSponsorId && (
                        <TextField
                          name={"sponsorId"}
                          label={"Sponsor"}
                          select
                          error={!!errors.sponsor}
                          helperText={errors.sponsor}
                          onChange={handleInputComboChange}
                          value={(selectedCampaign && selectedCampaign.campaign.sponsorId) || ""}
                          required
                          fullWidth
                          margin={"dense"}
                        >
                          <MenuItem value="" key="no_sponsor">
                            <Typography fontStyle={"italic"}>no sponsor selected</Typography>
                          </MenuItem>
                          {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                            return (
                              <MenuItem value={sponsorId} key={sponsorId}>
                                {sponsor.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      )}
                    </Grid>

                    {/* ----------- Agent ----------- */}
                    {userAgent && (
                      <Grid item xs={12}>
                        <Typography variant={"body1"}>
                          Agent: {userAgent.agent.company || "n/a"}
                        </Typography>
                      </Grid>
                    )}
                    {!userAgent && (
                      <Grid item xs={12}>
                        <TextField
                          name={"agentId"}
                          label={"Agent"}
                          select
                          error={!!errors.agent}
                          helperText={errors.agent}
                          onChange={handleInputComboChange}
                          value={(selectedCampaign && selectedCampaign.campaign.agentId) || ""}
                          fullWidth
                          margin={"dense"}
                        >
                          <MenuItem value="" key="no_agent">
                            <Typography fontStyle={"italic"}>no agent selected</Typography>
                          </MenuItem>
                          {Object.entries(agents).map(([agentId, agent]) => {
                            return (
                              <MenuItem value={agentId} key={agentId}>
                                {agent.company}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <TextField
                        error={!!errors.name}
                        helperText={errors.name}
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={selectedCampaign && selectedCampaign.campaign.name}
                        onChange={handleInputChange}
                      />
                    </Grid>

                    {/* ----------------- Language ---------------- */}
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Preferred language:{" "}
                        <LanguageSwitcher
                          initialLanguage={
                            selectedCampaign &&
                            selectedCampaign.campaign &&
                            selectedCampaign.campaign.language
                              ? (selectedCampaign.campaign.language as SupportedLanguages)
                              : "en"
                          }
                          onLanguageChange={handleLanguageChange}
                        />
                      </Typography>
                    </Grid>
                    {/* ----------------- Status ---------------- */}
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <FormLabel>Status</FormLabel>
                        <ToggleButtonGroup
                          id="status"
                          value={(selectedCampaign && selectedCampaign.campaign.status) || "new"}
                          exclusive
                          onChange={handleToggleChange}
                          aria-label="Campaign Status"
                          sx={{
                            marginTop: "8px",
                          }}
                        >
                          <ToggleButton
                            id="status_open"
                            value="open"
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: (theme) => theme.palette.success.main,
                                color: (theme) => theme.palette.success.contrastText,
                                "&:hover": {
                                  backgroundColor: (theme) => theme.palette.success.dark,
                                },
                              },
                            }}
                          >
                            Open
                          </ToggleButton>
                          <ToggleButton
                            id="status_closed"
                            value="closed"
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: (theme) => theme.palette.error.main,
                                color: (theme) => theme.palette.error.contrastText,
                                "&:hover": {
                                  backgroundColor: (theme) => theme.palette.error.dark,
                                },
                              },
                            }}
                          >
                            closed
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ padding: 2 }}>
                  <Box flexGrow={1}></Box>
                  <Button variant="contained" onClick={handleSaveCampaign} sx={{ mr: 2 }}>
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setSelectedCampaign(null);
                    }}
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </Button>
                </CardActions>
              </Card>
            )}
          </Box>
        </Box>
      </Box>
    </TitledPage>
  );
};

export default RecruitingCampaigns;
