import React, { useState, useEffect, useContext, useMemo, useRef } from "react";

import { TableVirtuoso, VirtuosoHandle } from "react-virtuoso";

import { Divider, TableCell, TextField } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

import { Button, Tooltip, IconButton } from "@mui/material";
import { Box, Card, CardHeader, CardContent } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import AddApplicantDialog from "../dialogs/dialogApplicant";

import { Applicant, ApplicantDoc, ApplicantsDict, PROPERTY_MAPPING } from "../../types/applicants";
import { isArray, isObject } from "../../utils/utils";
import { TitledPage } from "../../components/titled-page";
import { AuthContext } from "../../components/auth-provider";
import {
  deleteApplicant,
  loadApplicants,
  storeApplicant,
} from "../../data-functions/applicants-api";
import { deleteApplications, loadApplications } from "../../data-functions/applications-api";
import { SponsorsDict } from "../../types/sponsors";
import { AgentsDict } from "../../types/agents";
import { loadSponsors } from "../../data-functions/system-data_api";
import { loadAgents } from "../../data-functions/agent-api";
import { HeadCells, Order, getComparator } from "../../types/tables";
import { EnhancedTableHeadContents } from "../../components/enhanced-table";
import { VirtuosoTableComponents } from "../../components/enhanced-virtuoso-table";
import { SimpleTreeView } from "@mui/x-tree-view";
import { useNavigate, useParams } from "react-router-dom";

import { ApplicationsDict } from "../../types/visa-application";
import { SeasonsDict, WorkOrdersDict } from "../../types/orders";
import { loadSeasons, loadWorkOrders } from "../../data-functions/orders-api";
import { DocumentHistoryDialog } from "../dialogs/dialogDocumentHistory";
import { hasRole } from "../../utils/user-utils";

const headCells: HeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sortable: true,
  },
  {
    id: "surname",
    numeric: false,
    disablePadding: false,
    label: "Family Name",
    sortable: true,
  },
  {
    id: "agent",
    numeric: false,
    disablePadding: false,
    label: "Agent",
    sortable: false,
    tooltip: "Applicant's agent",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    sortable: false,
    align: "right",
  },
];

const ApplicantsPage: React.FC = () => {
  const { applicantId } = useParams<{ applicantId?: string }>();

  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [applicants, setApplicants] = useState<ApplicantsDict>({});
  const [selectedApplicantId, setSelectedApplicant] = useState("");
  const [applications, setApplications] = useState<ApplicationsDict>({});
  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [agents, setAgents] = useState<AgentsDict>({});
  const [editApplicant, setEditdApplicant] = useState<ApplicantDoc | null>(null);
  const [csvMappingEntries, setCsvMappingEntries] = useState<Record<string, string>>({});
  const [searchValue, setSearchValue] = useState<string>("");

  const [seasons, setSeasons] = useState<SeasonsDict>({});
  const [workOrders, setWorkOrders] = useState<WorkOrdersDict>({});

  const [historyDoc, setHistoryDoc] = useState<string>("");
  const [openHistory, setOpenHistory] = useState(false);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const firstMount = useRef(false);

  const { currentUser } = useContext(AuthContext)!;
  const navigate = useNavigate();

  const fetchSeasons = async () => {
    const seasonsDict = await loadSeasons(currentUser?.appUser);
    setSeasons(seasonsDict);
  };

  const fetchWorkOrders = async () => {
    const workOrdersDict = await loadWorkOrders(currentUser?.appUser);
    setWorkOrders(workOrdersDict);
  };

  const fetchApplicants = async () => {
    const applicantsDict: ApplicantsDict = await loadApplicants(currentUser?.appUser);

    setApplicants(applicantsDict);
  };

  const fetchSponsors = async () => {
    const sponsorsDict = await loadSponsors(currentUser?.appUser);

    if (sponsorsDict) {
      setSponsors(sponsorsDict);
    } else {
      setSponsors({});
    }
  };

  const fetchAgents = async () => {
    const agentsDict = await loadAgents(currentUser?.appUser);

    if (agentsDict) {
      setAgents(agentsDict);
    } else {
      setAgents({});
    }
  };

  // useEffect(() => {
  //   if (selectedApplicant && selectedApplicant.id) {
  //     displayApplicantDetails(selectedApplicant);
  //   }
  // }, [applicants]);

  useEffect(() => {
    firstMount.current = true;
    setCsvMappingEntries(PROPERTY_MAPPING);
    fetchSponsors();
    fetchAgents();
    fetchApplicants();
    fetchSeasons();
    fetchWorkOrders();
    // if (applicantId) {
    //   setSearchValue("");
    //   setSelectedApplicant(applicantId);
    // }
  }, []);

  useEffect(() => {
    setSelectedApplicant("");
    setSearchValue("");
    fetchSponsors();
    fetchAgents();
    fetchApplicants();
    // if (applicantId) {
    //   setSearchValue("");
    //   setSelectedApplicant(applicantId);
    // }
  }, [currentUser]);

  useEffect(() => {
    if (editApplicant) {
      handleAddNewApplicant(true);
    }
  }, [editApplicant]);

  useEffect(() => {
    loadCurrentApplications(selectedApplicantId);
  }, [selectedApplicantId]);

  const loadCurrentApplications = async (applicantId: string) => {
    const applications = await loadApplications(currentUser?.appUser, { applicantId });
    setApplications(applications);
  };

  const handleAddNewApplicant = (edit: boolean = false) => {
    setEditMode(edit && !!editApplicant);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditdApplicant(null);
    //fetchApplicants();
  };

  const handleSaveApplicant = async (applicant: ApplicantDoc) => {
    const appDoc = { ...applicant };
    const id = await storeApplicant(currentUser?.appUser, appDoc, true);
    if (!appDoc.id && id) {
      appDoc.id = id;
    }
    const newApplicants = { ...applicants };
    newApplicants[appDoc.id] = appDoc.applicant;

    setApplicants(newApplicants);
  };

  const handleShowHistory = async (documentId: string) => {
    setHistoryDoc(documentId);
    setOpenHistory(true);
  };

  //const handleExport = async () => {
  // const q = query(collection(db, "applicants"), where("application.status", "==", "new"));
  // const querySnapshot = await getDocs(q);
  // const applicants = querySnapshot.docs
  //   .map((doc) => doc.data() as Applicant)
  //   .filter((applicant) => applicant.application && applicant.application.dataFormCompleted);
  // // Convert applicants data to CSV format:
  // const csvData = addHOPSCosApplicants([], applicants);
  // const curentDate = new Date().toISOString().split("T")[0];
  // // Create a blob from the CSV data and download:
  // const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  // const url = window.URL.createObjectURL(blob);
  // const a = document.createElement("a");
  // a.style.display = "none";
  // a.href = url;
  // a.download = "applicants " + curentDate + ".csv"; // specify the file name
  // document.body.appendChild(a);
  // a.click();
  // document.body.removeChild(a);
  // window.URL.revokeObjectURL(url);
  //};

  async function deleteLinkedApplications(applicantId: string) {
    const applications = await loadApplications(currentUser?.appUser, { applicantId: applicantId });
    await deleteApplications(Object.keys(applications));
  }

  const handleDelete = async (applicantId: string) => {
    if (!applicantId) return;

    if (
      window.confirm("Are you sure you want to delete this applicant and all linked applications?")
    ) {
      deleteLinkedApplications(applicantId);
      await deleteApplicant(applicantId);
      const newApplicants = { ...applicants };
      delete newApplicants[applicantId];
      if (selectedApplicantId === applicantId) {
        navigate("/applicants");
        //setSelectedApplicant("");
      }
      setApplicants(newApplicants);
    }
  };

  const handleSelectApplicant = async (applicantId: string) => {
    if (applicantId) {
      console.log("selected Applicant", applicantId);
      // const loadedApplicant = await loadApplicant(applicant);
      const applicant = applicants[applicantId];
      if (applicant) {
        //setSelectedApplicant(applicantId);
        navigate(`/applicants/${applicantId}`);
      }
    }
  };

  const handleEditApplicant = async (applicant: ApplicantDoc) => {
    if (applicant) {
      setEditdApplicant(applicant);
    }
  };

  const displayNestedObject = (parentKey: string, idChain: string, obj: Object) => {
    const keys = Object.keys(obj).sort();
    return (
      <TreeItem itemId={idChain} key={idChain} label={csvMappingEntries[parentKey] || parentKey}>
        {keys.map((key) => {
          const value = obj[key as keyof typeof obj] as Object;
          const label = csvMappingEntries[key] || key;

          return isObject(value) ? (
            displayNestedObject(key, idChain + "_" + key, value)
          ) : isArray(value) ? (
            displayArray(key, idChain + "_" + key, value as Object[])
          ) : (
            <TreeItem
              itemId={idChain + "_" + key}
              key={idChain + "_" + key}
              label={label + ": " + (value || "-")}
            />
          );
        })}
      </TreeItem>
    );
  };

  const displayArray = (parentKey: string, idChain: string, obj: Object[]) => {
    return (
      <TreeItem itemId={idChain} key={idChain} label={csvMappingEntries[parentKey] || parentKey}>
        {obj.map((element, i) => {
          const label = " (" + i + ")";

          return isObject(element) ? (
            displayNestedObject(label, idChain + "_" + i, element)
          ) : isArray(element) ? (
            displayArray(label, idChain + "_" + i, element as Object[])
          ) : (
            <TreeItem
              itemId={idChain + "_" + i}
              key={idChain + "_" + i}
              label={label + ": " + (element || "-")}
            />
          );
        })}
      </TreeItem>
    );
  };

  const displayValue = (key: string, value: any) => {
    if (!value) return <TreeItem itemId={key} key={key} label={key + ": n/a"} />;
    switch (key) {
      case "agentId":
        return (
          <TreeItem
            itemId={key}
            key={key}
            label={"Agent: " + (agents[value as string].company || "n/a")}
          />
        );
      case "sponsorId":
        return (
          <TreeItem
            itemId={key}
            key={key}
            label={"Sponsor: " + sponsors[value as string].name || "n/a"}
          />
        );
      default:
        return <TreeItem itemId={key} key={key} label={key + ": " + value} />;
    }
  };

  const displayApplicantDetails = (applicantId: string) => {
    if (!applicantId) {
      return;
    }

    const applicant = applicants[applicantId];

    if (!applicant) {
      return;
    }

    const keys = Object.keys(applicant).sort();

    return (
      <>
        {Object.keys(applications).length > 0 && (
          <Card sx={{ marginBottom: "1rem" }}>
            <CardHeader title={`Applications (${Object.keys(applications).length})`} />
            <CardContent>
              {Object.entries(applications).map(([appId, application]) => {
                return (
                  <Box key={appId} sx={{ display: "flex", gap: "1rem" }}>
                    <div>
                      {application.seasonId ? seasons[application.seasonId].name : "no season"}
                    </div>
                    <div>
                      {application.workOrderId
                        ? workOrders[application.workOrderId].name
                        : "no order assigned"}
                    </div>
                  </Box>
                );
              })}
            </CardContent>
          </Card>
        )}
        <Card sx={{ height: "100%" }}>
          <CardHeader title={`Personal information (${applicant.name} ${applicant.surname})`} />
          <CardContent>
            <Box sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}>
              <SimpleTreeView aria-label="applicant details" sx={{ height: "100%" }}>
                {keys.map((key) => {
                  const value = applicant[key as keyof Applicant];

                  return isObject(value)
                    ? displayNestedObject(key, key, value as Object)
                    : isArray(value)
                    ? displayArray(key, key, value as Object[])
                    : displayValue(key, value);
                })}
              </SimpleTreeView>
            </Box>
          </CardContent>
        </Card>
      </>
    );
  };

  /* ------------ Search functions -------------- */
  const filteredApplicants = useMemo(
    () =>
      Object.entries(applicants).filter(([applicantId, appl]) => {
        const agent = appl.agentId ? agents[appl.agentId].company : "n/a";
        try {
          return (
            searchValue.length < 3 ||
            appl.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            appl.surname.toLowerCase().includes(searchValue.toLowerCase()) ||
            appl.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
            agent?.toLowerCase().includes(searchValue.toLowerCase())
          );
        } catch (err) {
          console.error("filter exception");
          console.log(appl);
          return false;
        }
      }),
    [searchValue, applicants, sponsors, agents]
  );

  const visibleRows = useMemo(
    () => filteredApplicants.slice().sort(getComparator(order, orderBy as keyof Applicant)),
    [order, orderBy, filteredApplicants]
  );

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (applicantId) {
      const index = visibleRows.findIndex(([ix]) => ix === applicantId);
      if (index === -1 && firstMount.current) {
        setSearchValue("");
      }
      setSelectedApplicant(applicantId);
      if (virtuosoRef.current && firstMount.current) {
        virtuosoRef.current.scrollToIndex({
          index,
          align: "center", // Adjust alignment as needed
          behavior: "auto", // Optional: smooth scrolling
        });
      }
      if (visibleRows.length > 0) {
        firstMount.current = false;
      }
    } else {
      setSelectedApplicant("");
    }
  }, [applicantId, visibleRows]);

  // const handleCallFunction = async () => {
  //   // Create an input element to select a file
  //   const input = document.createElement("input");
  //   input.type = "file";
  //   input.accept = "image/*";

  //   // Add an event listener to handle the file selection
  //   input.onchange = async (event) => {
  //     const file = (event.target as HTMLInputElement).files?.[0];

  //     if (file) {
  //       try {
  //         const reader = new FileReader();

  //         reader.onload = function (e: ProgressEvent<FileReader>) {
  //           if (e.target && e.target.result) {
  //             // Call the scanPassport API function with the selected file
  //             const scanPassport = httpsCallable(functions, "api/v1/scanPassport");
  //             const image = e.target.result as string;
  //             console.log("scanning the image...");

  //             scanPassport({ image })
  //               .then((result) => {
  //                 // Log the result to the console
  //                 console.log(result);
  //                 window.alert("Passport scanned successfully");
  //               })
  //               .catch((error) => {
  //                 console.error("Error scanning passport:", error);
  //               });
  //           }
  //         };

  //         reader.readAsDataURL(file);
  //       } catch (error) {
  //         console.error("Error scanning passport:", error);
  //       }
  //     }
  //   };

  //   // Trigger the file input click event
  //   input.click();
  // };

  return (
    <TitledPage title="Applicants">
      <Box>
        <Box display={"flex"} sx={{ gap: 2 }}>
          <Button variant="outlined" color="primary" onClick={() => handleAddNewApplicant()}>
            Add New Applicant
          </Button>

          <AddApplicantDialog
            open={open}
            onClose={handleClose}
            onSave={handleSaveApplicant}
            applicant={
              editMode && selectedApplicantId
                ? { id: selectedApplicantId, applicant: applicants[selectedApplicantId] }
                : null
            }
          />
          {hasRole(currentUser?.appUser, ["admin"]) && (
            <>
              <DocumentHistoryDialog
                open={openHistory}
                onClose={() => setOpenHistory(false)}
                documentId={historyDoc}
              />
              <Tooltip title="History">
                <span>
                  <IconButton
                    color="primary"
                    edge={"end"}
                    disabled={!selectedApplicantId}
                    onClick={() => handleShowHistory(selectedApplicantId)}
                  >
                    <InfoIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
          <Divider orientation="vertical" flexItem sx={{ marginTop: "8px", marginBottom: "4px" }} />
          {/* <Button variant="outlined" color="primary" onClick={handleCallFunction}>
            Test functions
          </Button> */}
          {/* <Button variant="outlined" color="primary" onClick={handleExport}>
          Export new applicants
        </Button> */}
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={handleSearchValueChange}
            sx={{ minWidth: "300px" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1rem",
            minHeight: "400px",
            height: "calc(100vh - 210px)",
          }}
        >
          <Box sx={{ width: "70%", marginRight: "2%" }}>
            <TableVirtuoso
              ref={virtuosoRef}
              style={{ height: "100%" }}
              data={visibleRows}
              context={{ selectedId: selectedApplicantId, handleSelect: handleSelectApplicant }}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() => (
                <EnhancedTableHeadContents
                  numSelected={selectedApplicantId ? 1 : 0}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={() => {}}
                  onRequestSort={handleRequestSort}
                  rowCount={visibleRows.length}
                  headCells={headCells}
                />
              )}
              itemContent={(index, [applicantId, applicant]) => (
                <>
                  <TableCell scope="row" size="small">
                    {applicant.name}
                  </TableCell>
                  <TableCell size="small">{applicant.surname}</TableCell>
                  <TableCell size="small">
                    {(applicant.agentId && agents[applicant.agentId]?.company) || "n/a"}
                  </TableCell>
                  <TableCell align="right" style={{ width: 120 }} size="small">
                    <Tooltip title="Edit applicant">
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleEditApplicant({ id: applicantId, applicant });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(applicantId || "");
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </>
              )}
            />
          </Box>
          <Box sx={{ width: "48%" }}>{displayApplicantDetails(selectedApplicantId)}</Box>
        </Box>
      </Box>
    </TitledPage>
  );
};

export default ApplicantsPage;
