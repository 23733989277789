import { CollectionReference, Query, WhereFilterOp, limit, query, where } from "firebase/firestore";
import { Filter, LogEntry } from "../types/commons";
import { httpsCallable } from "firebase/functions";
import { functions } from "../types/firebase";

export const applyFilter = (
  customQuery: Query | CollectionReference,
  filter: Filter = {},
  cLimit: number = 0
) => {
  Object.keys(filter).forEach((key) => {
    const value = filter[key];
    customQuery = query(customQuery, where(key, value.operator as WhereFilterOp, value.value));
  });
  if (cLimit > 0) customQuery = query(customQuery, limit(cLimit));
  return customQuery;
};

export const logEvent = async (logEntry: LogEntry) => {
  const logEvent = httpsCallable(functions, "api/v1/logEvent");

  try {
    const result = await logEvent(logEntry);
    return result.data;
  } catch (err) {
    console.error("Error logging event:", err);
    return { status: "ERROR", error: err };
  }
};
