import { Timestamp } from "firebase/firestore";
import { Candidate, Candidate_FB, CandidateStatus } from "../types/recruiting";
import { Chip, Typography } from "@mui/material";
import { fbTimestampToDateTime } from "./utils";
import { BASE_FUNCTIONS_URL } from "../types/firebase";

export const getCandidateStatusText = (status: CandidateStatus | undefined) => {
  switch (status) {
    case "verified":
      return "Verified";
    case "approved":
      return "Approved";

    case "applicant":
      return "Sent for Visa";

    case "rejected":
      return "Rejected";

    case "DnI":
      return "Rejected";

    case "interviewed":
      return "Interviewed";

    default:
      return "New";
  }
};

export const getCandidateStatusLabel = (status: CandidateStatus | undefined) => {
  return <Typography paddingRight={"3px"}>{getCandidateStatusText(status)}</Typography>;
};

export const getCandidateStatusChip = (status: CandidateStatus | undefined) => {
  switch (status) {
    case "verified":
      return <Chip label={getCandidateStatusText(status)} color="primary" />;
    case "approved":
      return <Chip label={getCandidateStatusText(status)} color="success" />;

    case "applicant":
      return <Chip label={getCandidateStatusText(status)} color="success" />;

    case "rejected":
      return <Chip label={getCandidateStatusText(status)} color="error" />;

    case "DnI":
      return <Chip label={getCandidateStatusText(status)} color="error" />;

    case "interviewed":
      return <Chip label={getCandidateStatusText(status)} color="primary" />;

    default:
      return <Chip label={getCandidateStatusText(status)} color="primary" variant="outlined" />;
  }
};

export const setCandidateToFB = (candidate: Candidate): Candidate_FB => {
  const cand: Candidate_FB = {
    ...candidate,
    verification: candidate.verification && {
      verificationCode: candidate.verification.verificationCode,
      expiryDate: Timestamp.fromDate(new Date(candidate.verification.expiryDate)),
    },
  };

  if (cand.verification !== undefined) {
    if (cand.verification && candidate.verification && candidate.verification.confirmationCode) {
      cand.verification.confirmationCode = candidate.verification.confirmationCode;
    }

    if (cand.verification && candidate.verification && candidate.verification.verifiedOn) {
      cand.verification.verifiedOn = Timestamp.fromDate(
        new Date(candidate.verification.verifiedOn)
      );
    }
  } else {
    delete cand.verification;
  }

  return cand;
};

export const getCandidateFromFB = (candidate: Candidate_FB): Candidate => {
  const cand: Candidate = {
    ...candidate,
    verification: candidate.verification && {
      verificationCode: candidate.verification.verificationCode,
      expiryDate: fbTimestampToDateTime(candidate.verification.expiryDate),
    },
  };

  if (cand.verification && candidate.verification && candidate.verification.confirmationCode) {
    cand.verification.confirmationCode = candidate.verification.confirmationCode;
  }

  if (cand.verification && candidate.verification && candidate.verification.verifiedOn) {
    cand.verification.verifiedOn = fbTimestampToDateTime(candidate.verification.verifiedOn);
  }
  return cand;
};

export const sendEmailVerificationEmail = async (candidateIds: string[], templateId: string) => {
  const apiKey = process.env.REACT_APP_FUNCTIONS_API_KEY || "";
  const response = await fetch(BASE_FUNCTIONS_URL + "/public/mailCandidates", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-Key": apiKey,
    },
    body: JSON.stringify({
      candidates: candidateIds,
      template: templateId,
    }),
  });

  console.log("response: ", response);
  return response;
};
