import { useContext, useEffect, useMemo, useState } from "react";
import { EMPTY_VISA_APPLICATION, ApplicationDoc } from "../../types/visa-application";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { ContentCopy as CopyIcon, Email as EmailIcon } from "@mui/icons-material";
import { BASE_URL, copyToClipboard, getDefaultExpiryDate } from "../../utils/utils";
import { AuthContext } from "../../components/auth-provider";
import {
  VirtualPresentationsDict,
  EMPTY_VP_INSTANCE,
  VirtualPresentationInstance,
} from "../../types/virtual-presentations";
import { loadVirtualPresentations } from "../../data-functions/virtual_presentataions_api";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { loadApplication } from "../../data-functions/applications-api";

interface ApplicationVPDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (application: ApplicationDoc) => void;
  onSendLink: () => void;
  application: ApplicationDoc;
}

const EMPTY_APPL_DOC = {
  id: "",
  application: { ...EMPTY_VISA_APPLICATION },
};

const EMPTY_ERRORS = {
  presentation: "",
  expiry_date: "",
};

const ApplicationVirtualPresentationDialog = ({
  open,
  onClose,
  onSave,
  onSendLink,
  application: initialApplication,
}: ApplicationVPDialogProps) => {
  const [appDoc, setApplication] = useState<ApplicationDoc>({ ...EMPTY_APPL_DOC });
  const [presentations, setPresentations] = useState<VirtualPresentationsDict>({});
  const [linkUrl, setLinkUrl] = useState("");
  const [errors, setErrors] = useState<{
    presentation: string;
    expiry_date: string;
  }>({ ...EMPTY_ERRORS });

  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackError, setOpenSnackError] = useState(false);

  const { currentUser } = useContext(AuthContext)!;

  useEffect(() => {
    const fetchPresentations = async () => {
      const presentationsDict: VirtualPresentationsDict = await loadVirtualPresentations(
        currentUser?.appUser,
        { status: { value: "enabled", operator: "==" } }
      );

      if (presentationsDict) {
        setPresentations(presentationsDict);
      } else {
        setPresentations({});
      }
    };

    fetchPresentations();
  }, [currentUser]);

  useEffect(() => {
    const initializeApplication = async () => {
      let newApplication: ApplicationDoc;
      if (initialApplication.application) {
        newApplication = _.cloneDeep(initialApplication);
        if (initialApplication.id) {
          const loadedApplication = await loadApplication(
            currentUser?.appUser,
            initialApplication.id
          );
          if (loadedApplication) {
            newApplication = loadedApplication;
          }
        }
      } else {
        newApplication = {
          ...EMPTY_APPL_DOC,
        };
      }

      if (!newApplication.application.virtualPresentation) {
        newApplication.application.virtualPresentation = {
          ...EMPTY_VP_INSTANCE,
          expiry_date: getDefaultExpiryDate(7),
          link_id: uuidv4(),
        };
      }

      const baseUrl = BASE_URL + "/virtual-presentation/";
      setLinkUrl(baseUrl + newApplication.application.virtualPresentation.link_id);

      setApplication(newApplication);
    };

    initializeApplication();
  }, [initialApplication]);

  const validateInput = () => {
    let newErrors = { ...EMPTY_ERRORS };
    let formIsValid = true;

    if (!appDoc.application.virtualPresentation) {
      return false;
    }

    if (!appDoc.application.virtualPresentation.virtualPresentationId) {
      newErrors.presentation = "Please select a virtual presentation";
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSave = async () => {
    if (!validateInput()) {
      return;
    }

    onSave(appDoc);
    setApplication({ ...EMPTY_APPL_DOC });
    handleClose();
  };
  const handleClose = () => {
    setErrors({ ...EMPTY_ERRORS });
    onClose();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.name) return;

    const newApplication: ApplicationDoc = _.cloneDeep(appDoc);

    if (!newApplication.application.virtualPresentation) {
      return;
    }

    const key = event.target.name as keyof VirtualPresentationInstance;
    switch (key) {
      case "expiry_date":
        newApplication.application.virtualPresentation.expiry_date = event.target.value;
        break;

      default:
        // newApplication.application[key] = event.target.value;
        break;
    }

    setApplication(newApplication);
  };

  const onInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (appDoc) {
      const value = event.target.value;
      const newApplication: ApplicationDoc = _.cloneDeep(appDoc);
      if (!newApplication.application.virtualPresentation) {
        return;
      }
      switch (event.target.name) {
        case "virtualPresentationId":
          newApplication.application.virtualPresentation.virtualPresentationId = value;
          break;
        default:
          return;
      }

      console.log("newApplication: ", newApplication);
      setApplication(newApplication);
    }
  };

  const onCopySuccess = () => {
    setOpenSnack(true);
  };

  const onCopyError = (reason: any) => {
    console.error(reason);
    setOpenSnackError(true);
  };

  const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setOpenSnackError(false);
  };

  const sortedPresentations = useMemo(
    () => Object.entries(presentations).sort((a, b) => (a[1].name > b[1].name ? 1 : -1)),
    [presentations]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
      <DialogTitle>Virtual Presentation Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <Divider> Assign a virtual presentation </Divider>
          </Grid>
          {/* ----------- Presentation ----------- */}
          <Grid item xs={12} md={8}>
            <TextField
              name={"virtualPresentationId"}
              label={"Virtual Presentation"}
              select
              error={!!errors.presentation}
              helperText={errors.presentation}
              onChange={onInputComboChange}
              value={
                (appDoc &&
                  appDoc.application &&
                  appDoc.application.virtualPresentation?.virtualPresentationId) ||
                ""
              }
              required
              fullWidth
              margin={"dense"}
            >
              <MenuItem value="" key="no_presentation">
                <Typography fontStyle={"italic"}>no presentation selected</Typography>
              </MenuItem>
              {Object.values(sortedPresentations).map(([presentationId, presentation]) => {
                return (
                  <MenuItem value={presentationId} key={presentationId}>
                    {presentation.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="expiry_date"
              label="Virtual presentation expiration date"
              error={!!errors.expiry_date}
              helperText={errors.expiry_date}
              type="date"
              value={
                (appDoc &&
                  appDoc.application &&
                  appDoc.application.virtualPresentation &&
                  appDoc.application.virtualPresentation.expiry_date) ||
                ""
              }
              onChange={handleInputChange}
              margin={"dense"}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          {appDoc.application.virtualPresentation?.virtualPresentationId && (
            <>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Divider> Presentation Link </Divider>
              </Grid>
              <Grid item xs={12}>
                <Link href={linkUrl} target="_blank" rel="noreferrer">
                  {linkUrl}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Box
                  marginTop="16px"
                  display="flex"
                  flexDirection="row"
                  gap={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button variant="contained" startIcon={<EmailIcon />} onClick={onSendLink}>
                    Send Link
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<CopyIcon />}
                    onClick={() => copyToClipboard(linkUrl, onCopySuccess, onCopyError)}
                  >
                    Copy to Clipboard
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        <Snackbar
          open={openSnack}
          autoHideDuration={1000}
          onClose={handleCloseSnack}
          message="Link successfully copied to the clipoard!"
        />
        <Snackbar open={openSnackError} autoHideDuration={1000} onClose={handleCloseSnack}>
          <Alert onClose={handleCloseSnack} severity="error" sx={{ width: "100%" }}>
            The Link could not be copied to the clipboard!
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplicationVirtualPresentationDialog;
