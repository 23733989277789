import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { SponsorDoc } from "../../types/sponsors";
import { FormattedMessage, IntlProvider } from "react-intl";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { messages, SupportedLanguages } from "../../languages/languages";
import { CustomTextField } from "../../components/custom-textfield";
import {
  CampaignDoc,
  CampaignsDict,
  Candidate,
  CandidateDoc,
  CandidateErrors,
  EMPTY_CANDIDATE_DOC,
  EMPTY_CANDIDATE_ERRORS,
  VERIFICATION_EXPIRY_HOURS,
} from "../../types/recruiting";
import { isEMailValid, isNameValid, isPhoneValid } from "../../utils/utils";
import { Circle, CircleOutlined } from "@mui/icons-material";
import { RadioYesNo } from "../../components/radio-button-yes-no";
import { loadCampaign, loadCandidates, saveCandidate } from "../../data-functions/recruiting_api";
import { loadSponsor } from "../../data-functions/system-data_api";
import emailSpellChecker from "@zootools/email-spell-checker";
import { v4 as uuidv4 } from "uuid";

import { sendEmailVerificationEmail } from "../../utils/candidate-utils";

declare global {
  interface Window {
    mrz_worker: any;
  }
}

type ParamTypes = {
  cid: string;
};

const hint_text =
  "Why do we need your personal data and how are we going to use it? " +
  "By submitting this form you agree that Global Workforce is an " +
  "administrator of personal data and as such will process it in accordance with GDPR " +
  "- EU General Data Protection Regulation 2016/679. " +
  "The collection of your personal data is necessary in connection with your application " +
  "for our programmes. Please read more about our Privacy Policy:";
const privacy_policy =
  "I agree to the Terms and Conditions of the Privacy Policy of Global Workforce.";

const ACTIVE_STEP_EMPTY = -1;
const ACTIVE_STEP_REGISTER = 0;
const ACTIVE_STEP_FINISHED = 1;
const ACTIVE_STEP_INVALID_CAMPAIGN = 2;
const ACTIVE_STEP_EXPIRED_CAMPAIGN = 3;
// const ACTIVE_STEP_SCAN_PASSPORT = 4;
const ACTIVE_STEP_WELCOME = 5;

export const RegisterCandidate = () => {
  const { cid } = useParams<ParamTypes>();
  const [campaign, setCampaign] = useState<CampaignDoc | null>(null);
  const [sponsor, setSponsor] = useState<SponsorDoc | null>(null);
  const [language, setLanguage] = useState<SupportedLanguages>("en");
  const [candidate, setCandidate] = useState<CandidateDoc>({ ...EMPTY_CANDIDATE_DOC });
  const [activeStep, setActiveStep] = useState<number>(ACTIVE_STEP_WELCOME);
  const [errors, setErrors] = useState<CandidateErrors>({ ...EMPTY_CANDIDATE_ERRORS });
  const [emailSuggestion, setEmailSuggestion] = useState<string>("");

  const registerInProgress = useRef<boolean>(false);

  useEffect(() => {
    const fetchSponsor = async () => {
      if (cid) {
        const campaignDoc = await loadCampaign(cid);
        let newCampaign;
        if (campaignDoc) {
          setCampaign(campaignDoc);
          newCampaign = campaignDoc.campaign;
        } else {
          setCampaign(null);
          console.error("Campaign ID: " + cid + " was not found");
          setActiveStep(ACTIVE_STEP_INVALID_CAMPAIGN);
          return;
        }

        const sponsor = await loadSponsor(newCampaign.sponsorId);

        if (sponsor) {
          setSponsor(sponsor);
        }

        if (newCampaign.language && newCampaign.language !== language) {
          setLanguage(newCampaign.language as SupportedLanguages);
        }

        if (newCampaign.status === "closed") {
          setActiveStep(ACTIVE_STEP_EXPIRED_CAMPAIGN);
        } else {
          setActiveStep(ACTIVE_STEP_WELCOME);
        }
      } else {
        console.error("Campaign ID: " + cid + " was not found");
        setActiveStep(ACTIVE_STEP_INVALID_CAMPAIGN);
      }
    };

    fetchSponsor();
    setEmailSuggestion("");
  }, [cid]);

  // const handleLoadPassport = async () => {
  // Create an input element to select a file
  // const input = document.createElement("input");
  // input.type = "file";
  // input.accept = "image/*";
  // // Add an event listener to handle the file selection
  // input.onchange = async (event) => {
  //   const file = (event.target as HTMLInputElement).files?.[0];
  //   if (file) {
  //     try {
  //       const reader = new FileReader();
  //       reader.onload = function (e: ProgressEvent<FileReader>) {
  //         console.log("reader onload", e.target);
  //         console.log("worker", worker);
  //         if (e.target && e.target.result && worker) {
  //           console.log("posting message");
  //           worker.postMessage({
  //             cmd: "process",
  //             image: e.target.result,
  //           });
  //         }
  //       };
  //       console.log("reading file");
  //       reader.readAsDataURL(file);
  //     } catch (error) {
  //       console.error("Error scanning passport:", error);
  //     }
  //   }
  // };
  // // Trigger the file input click event
  // input.click();
  // };

  const validateInput = async (candidate: Candidate) => {
    let newErrors: CandidateErrors = { ...EMPTY_CANDIDATE_ERRORS };
    let formIsValid = true;

    if (!(candidate.name && isNameValid(candidate.name))) {
      newErrors.name = "Name is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!(candidate.surname && isNameValid(candidate.surname))) {
      newErrors.surname = "Surname is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!isPhoneValid(candidate.phone)) {
      newErrors.phone = "Phone number is required and should only contain numbers!";
      formIsValid = false;
    }

    if (!candidate.privacy_policy_accepted) {
      formIsValid = false;
      newErrors.privacy_policy_accepted =
        "Please, accept the privacy policy, so that we can continue with your application!";
    }

    if (candidate.email && isEMailValid(candidate.email)) {
      const cDict: CampaignsDict = {};

      if (campaign) {
        cDict[campaign.id] = campaign.campaign;
      }

      const duplCandidates = await loadCandidates(null, cDict, {
        email: { value: candidate.email.trim(), operator: "==" },
      });

      const candidateFound = Object.keys(duplCandidates).length > 0;

      if (candidateFound) {
        newErrors.email = "A candidate with this email address already exists";
        formIsValid = false;
      }
    } else {
      newErrors.email = "Please enter a valid email address";
      formIsValid = false;
    }

    if (candidate.has_passport && (!candidate.passportId || candidate.passportId.length <= 3)) {
      newErrors.passportId = "Please, enter a valid passport number";
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;

    let newCandidate;

    if (key === "privacy_policy") {
      newCandidate = {
        ...candidate,
        candidate: { ...candidate.candidate, privacy_policy_accepted: event.target.checked },
      };
    } else if (key === "has_passport") {
      newCandidate = {
        ...candidate,
        candidate: { ...candidate.candidate, has_passport: event.target.value === "true" },
      };
    } else if (key === "passportId") {
      newCandidate = {
        ...candidate,
        candidate: { ...candidate.candidate, passportId: event.target.value },
      };
      newCandidate.candidate.has_passport = event.target.value.length > 0;
    } else {
      newCandidate = {
        ...candidate,
        candidate: { ...candidate.candidate, [key]: event.target.value },
      };
    }

    setCandidate(newCandidate);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // Your logic here
    console.log("Input field lost focus:", event.target.value);
    const email = event.target.value;
    if (email) {
      const suggestedEmail = emailSpellChecker.run({
        email,
      });
      if (suggestedEmail) {
        // Make the warning container visible,

        // and add warning text
        setEmailSuggestion(suggestedEmail.full);
      } else {
        // Else, hide the warning container
        setEmailSuggestion("");
      }
    }
  };

  const handleSave = async () => {
    if (registerInProgress.current === true) {
      console.log("registering in progress");
      return;
    }

    registerInProgress.current = true;

    const newCandidate = { ...candidate.candidate };
    newCandidate.name = newCandidate.name.trim();
    newCandidate.surname = newCandidate.surname.trim();
    newCandidate.email = newCandidate.email.toLowerCase().trim();
    newCandidate.phone = newCandidate.phone.trim();
    if (newCandidate.passportId) {
      newCandidate.passportId = newCandidate.passportId.trim();
    }
    newCandidate.preferredLanguage = language;
    newCandidate.sponsorId = sponsor ? sponsor.id : "";

    const isValid = await validateInput(newCandidate);
    if (!isValid) {
      registerInProgress.current = false;
      return;
    }

    if (campaign && campaign.id) {
      newCandidate.campaignId = campaign.id;
      newCandidate.agentId = campaign.campaign.agentId || "";
      newCandidate.verification = {
        verificationCode: uuidv4(),
        expiryDate: new Date(Date.now() + VERIFICATION_EXPIRY_HOURS * 60 * 60 * 1000).toISOString(),
      };

      saveCandidate(null, { id: "", candidate: newCandidate }, true)
        .then((result) => {
          if (result) {
            setCandidate(result);
            sendEmailVerificationEmail([result.id], "candidate_verification");
          }
          setActiveStep(ACTIVE_STEP_FINISHED);
        })
        .catch((err) => {
          registerInProgress.current = false;
          console.error(err.message);
        });
    }

    registerInProgress.current = false;
  };

  const handleNext = () => {
    if (!candidate.candidate.privacy_policy_accepted) {
      setErrors({
        ...errors,
        privacy_policy_accepted:
          "Please, accept the privacy policy, so that we can continue with your application!",
      });
      return;
    }

    setActiveStep(ACTIVE_STEP_REGISTER);
  };

  const getFinished = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="register_finished_title"
            defaultMessage="Thank you for registering!"
          />
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="register_finished_email"
            defaultMessage="We have sent you a link to confirm your email! To finish the registration process, please click on the link in the email."
          />
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="register_finished_hint1"
            values={{ hours: 6 }}
            defaultMessage={
              "You have {hours} hours to confirm your email address! " +
              "Without confirmation, your registration will be deleted and you'll not be able to attend the interview."
            }
          />
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="register_finished_hint2"
            defaultMessage="If you haven't received our email, check your spam folder."
          />
        </Typography>
      </Box>
    );
  };

  const getWelcomePage = () => {
    return (
      <Box
        sx={{
          padding: {
            xs: "1rem",
            md: "3rem",
          },
          paddingTop: "1rem",
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_welcome_title"
            defaultMessage="Working in the United Kingdom with a visa"
          />
        </Typography>
        <Typography sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="register_welcome_hint1"
            defaultMessage="Welcome to the Global Workforce registration page. Here you can register for our interview process."
          />
        </Typography>

        <Grid container spacing={2} marginTop={"1rem"} marginBottom={"1rem"}>
          {/* ---------- Privacy Policy ----------*/}
          <Grid item xs={12}>
            <Box>
              <Typography>
                <FormattedMessage id="privacy_hint" defaultMessage={hint_text} />

                <Link
                  href="https://ibcbulgaria.com/privacypolicy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://ibcbulgaria.com/privacypolicy/
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!!errors.privacy_policy_accepted}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="privacy_policy"
                    size="medium"
                    checkedIcon={<Circle />}
                    icon={<CircleOutlined />}
                    onChange={handleInputChange}
                  />
                }
                label={<FormattedMessage id="privacy_policy" defaultMessage={privacy_policy} />}
              />
              <FormHelperText>
                {errors.privacy_policy_accepted && (
                  <FormattedMessage
                    id="privacy_policy_error"
                    defaultMessage={errors.privacy_policy_accepted}
                  />
                )}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" onClick={handleNext}>
                <FormattedMessage id="button_next" defaultMessage={"Register"} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getForm = () => {
    return (
      <Box
        sx={{
          padding: {
            xs: "1rem",
            md: "3rem",
          },
          paddingTop: "1rem",
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_register_title"
            defaultMessage="Working in the United Kingdom with a visa"
          />
        </Typography>
        <Typography sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="register_register_hint1"
            defaultMessage="You can register for our interview process with your names, email address and telephone number."
          />
        </Typography>

        {/* ---------- Data Form ---------- */}
        <Grid container spacing={2} marginTop={"1rem"} marginBottom={"1rem"}>
          {/* <Grid item xs={12}>
            <Button variant="contained" onClick={handleLoadPassport}>
              load passport
            </Button>
          </Grid> */}

          <Grid item xs={12} md={6}>
            <CustomTextField
              error={!!errors.name}
              helperText={errors.name}
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              required
              value={candidate.candidate.name || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              error={!!errors.surname}
              helperText={errors.surname}
              margin="dense"
              name="surname"
              label="Surname"
              type="text"
              fullWidth
              required
              value={candidate.candidate.surname || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              error={!!errors.email}
              helperText={errors.email}
              margin="dense"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              required
              value={candidate.candidate.email || ""}
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
            {emailSuggestion && (
              <Chip
                color="warning"
                label={
                  <FormattedMessage
                    id="email_suggestion"
                    defaultMessage={`Press to use {email} instead`}
                    values={{ email: emailSuggestion }}
                  />
                }
                size={"medium"}
                variant={"outlined"}
                onClick={() => {
                  setCandidate({
                    ...candidate,
                    candidate: { ...candidate.candidate, email: emailSuggestion },
                  });
                  setEmailSuggestion("");
                }}
                onDelete={() => {
                  setEmailSuggestion("");
                }}
                sx={{
                  width: "100%",
                  marginTop: "0.5rem",
                  "& .MuiChip-deleteIcon": {
                    marginLeft: "auto",
                  },
                  height: "auto",
                  minHeight: "2rem",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                  },
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name="phone"
              label="Phone number"
              error={!!errors.phone}
              helperText={errors.phone}
              type="tel"
              value={candidate.candidate.phone || ""}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RadioYesNo
              id="has_passport"
              value={!!candidate.candidate.has_passport}
              label="Do you have a travel passport?"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              error={!!errors.passportId}
              helperText={errors.passportId}
              margin="dense"
              name="passportId"
              label="Passport Number"
              type="text"
              fullWidth
              value={candidate.candidate.passportId || ""}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" onClick={handleSave}>
                <FormattedMessage id="button_register" defaultMessage={"Register"} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getInvalidCampaign = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" color="error" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_campaign_invalid_title"
            defaultMessage="The regitration link you have been provided is invalid!"
          />
        </Typography>
      </Box>
    );
  };

  const getExpiredCampaign = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" color="error" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_campaign_expired_title"
            defaultMessage="This campaign has alread expired and does not accept registrations anymore!"
          />
        </Typography>
      </Box>
    );
  };

  const getEmpty = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" color="error" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage id="register_empty_title" defaultMessage="Seasonal work in the UK" />
        </Typography>
      </Box>
    );
  };

  const getActivePage = () => {
    switch (activeStep) {
      case ACTIVE_STEP_WELCOME:
        return getWelcomePage();
      // case ACTIVE_STEP_SCAN_PASSPORT:
      //   return getScanPassport();
      case ACTIVE_STEP_REGISTER:
        return getForm();
      case ACTIVE_STEP_FINISHED:
        return getFinished();
      case ACTIVE_STEP_INVALID_CAMPAIGN:
        return getInvalidCampaign();
      case ACTIVE_STEP_EXPIRED_CAMPAIGN:
        return getExpiredCampaign();
      case ACTIVE_STEP_EMPTY:
        return getEmpty();
      default:
        return getEmpty();
    }
  };

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            src={sponsor ? sponsor.sponsor.logo : ""}
            alt="logo"
            style={{ maxHeight: "200px", width: "auto" }}
          />
        </Box>
        <Card sx={{ marginTop: "1rem", padding: "1rem" }}>{getActivePage()}</Card>
      </Container>
    </IntlProvider>
  );
};
