import { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useTheme,
  Avatar,
  Alert,
  AlertColor,
  Snackbar,
  LinearProgress,
} from "@mui/material";
import { Mail, Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import { gwfMainBlue } from "../../theme";
import { AgentsDict } from "../../types/agents";
import { SponsorsDict } from "../../types/sponsors";
import { AuthContext } from "../../components/auth-provider";
import { loadAgents } from "../../data-functions/agent-api";
import { loadSponsors } from "../../data-functions/system-data_api";
import { EMPTY_APP_USER, AppUserDoc } from "../../types/users";
import { copyToClipboard, generatePassword, isEMailValid, isNameValid } from "../../utils/utils";
import {
  createUser,
  existsAppUserEMail,
  sendLoginCredentials,
} from "../../data-functions/users-api";

interface AddUserDialogProps {
  open: boolean;
  onClose: () => void;
}

interface AlertProps {
  open: boolean;
  severity?: AlertColor;
  message?: string;
}

const STEP_ADD_USER = 0;
const STEP_SEND_CREDENTIALS = 1;

const EMPTY_ERRORS = { name: "", surname: "", email: "" };

const AddUserDialog = ({ open, onClose }: AddUserDialogProps) => {
  const [agents, setAgents] = useState<AgentsDict>({});
  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [appUser, setAppUser] = useState<AppUserDoc>({ id: "", user: { ...EMPTY_APP_USER } });
  const [adminSponsorId, setAdminSponsorId] = useState<string>("");

  const [step, setStep] = useState<number>(STEP_ADD_USER);
  const [alert, setAlert] = useState<AlertProps>({ open: false });
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [errors, setErrors] = useState<{
    name: string;
    surname: string;
    email: string;
    sponsorId?: string;
    agentId?: string;
    role?: string;
  }>({ ...EMPTY_ERRORS });

  const { currentUser } = useContext(AuthContext)!;
  const theme = useTheme();

  const fetchAgents = async () => {
    const agentsDict: AgentsDict = await loadAgents(currentUser?.appUser);
    setAgents(agentsDict);
  };

  const fetchSponsors = async () => {
    const sponsorsDict: SponsorsDict = await loadSponsors(currentUser?.appUser);
    setSponsors(sponsorsDict);
  };

  useEffect(() => {
    fetchSponsors();
    fetchAgents();
    setAdminSponsorId(currentUser?.appUser?.sponsorId || "");
    setErrors({ ...EMPTY_ERRORS });
  }, [currentUser]);

  useEffect(() => {
    if (open) {
      setAppUser({ id: "", user: { ...EMPTY_APP_USER } });
      setErrors({ ...EMPTY_ERRORS });
      setStep(STEP_ADD_USER);
    }
  }, [open]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.name + " " + event.target.value);
    const newAppUser: AppUserDoc = {
      ...appUser,
      user: { ...appUser.user, [event.target.name]: event.target.value },
    };

    console.log(newAppUser);
    setAppUser(newAppUser);
  };

  const onInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newUser = { ...appUser };
    switch (event.target.name) {
      case "sponsorId":
        if (value !== appUser.user.sponsorId) {
          newUser.user.sponsorId = value;
          newUser.user.agentId = "";
        }
        break;
      case "agentId":
        newUser.user.agentId = value;
        break;
      default:
        return;
    }
    setAppUser(newUser);
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const role = event.target.name.split("-")[1];
    let newRoles = [...appUser.user.role];
    if (event.target.checked) {
      newRoles.push(role);
    } else {
      newRoles = newRoles.filter((r) => r !== role);
    }
    setAppUser({ ...appUser, user: { ...appUser.user, role: newRoles } });
  };

  const validateInput = async () => {
    let newErrors = {
      name: "",
      surname: "",
      email: "",
      expiry_date: "",
      sponsorId: "",
      agentId: "",
      role: "",
    };
    let formIsValid = true;

    if (!appUser.user.sponsorId) {
      newErrors.sponsorId = "Please, select a sponsor for this applicant";
      formIsValid = false;
    }

    if (!(appUser.user.name && isNameValid(appUser.user.name))) {
      newErrors.name = "Name is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!(appUser.user.surname && isNameValid(appUser.user.surname))) {
      newErrors.surname = "Surname is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!(appUser.user.email && isEMailValid(appUser.user.email))) {
      newErrors.email = "Please enter a valid email address";
      formIsValid = false;
    } else {
      const userExists = await existsAppUserEMail(appUser.user.email);
      if (userExists) {
        formIsValid = false;
        newErrors.email = "User with this email address already exists";
      }
    }

    if (appUser.user.role.length === 0) {
      newErrors.role = "Please, select a role for this applicant";
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleAddUser = async () => {
    const isValid = await validateInput();
    if (!isValid) {
      return;
    }

    const newPassword = generatePassword(16);
    setShowProgress(true);
    createUser(appUser.user, newPassword)
      .then((result) => {
        if (result.status === "ERROR") {
          console.error("Error creating user: ", result.error);
          window.alert("Error creating user: " + result.error);
        }
        const newUser = { ...appUser };
        newUser.user.id = result.user?.id || "";
        newUser.id = result.id || "";

        console.log("created new user: ", newUser);
        setAppUser(newUser);

        setPassword(newPassword);
        setAlert({ open: false });
        setShowProgress(false);
        setStep(STEP_SEND_CREDENTIALS);
      })
      .catch((err) => {
        setAlert({ open: true, severity: "error", message: err.message });
      });
  };

  const handleSendCredentials = async () => {
    sendLoginCredentials(appUser.user, password)
      .then((result) => {
        setShowProgress(false);
        if (result.status === "ERROR") {
          console.error("Error creating user: ", result.error);
          setAlert({ open: true, severity: "error", message: "Error sending credentials" });
        } else {
          setAlert({ open: true, severity: "success", message: "Credentials sent" });
        }
      })
      .catch((err) => {
        setAlert({ open: true, severity: "error", message: "Error sending credentials" });
        setShowProgress(false);
      });
    setShowProgress(true);
  };

  const handleCopyClipboard = () => {
    copyToClipboard(password);
    setAlert({ open: true, severity: "info", message: "Password copied to clipboard" });
  };

  const getDialogTitleColor = (step: number) => {
    switch (step) {
      case STEP_ADD_USER:
        return gwfMainBlue;
      case STEP_SEND_CREDENTIALS:
        return theme.palette.success.main;
      default:
        return gwfMainBlue;
    }
  };

  const getDialogTitleText = (step: number) => {
    switch (step) {
      case STEP_ADD_USER:
        return "Add new user";
      case STEP_SEND_CREDENTIALS:
        return "New user added";
      default:
        return gwfMainBlue;
    }
  };

  const getDialogContentAddNewUser = () => {
    return (
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <DialogContent>
          <Card sx={{ border: "solid 1px #DFDFDF" }}>
            <CardHeader
              title="User details"
              sx={{
                backgroundColor: "#FAFAFA",
                color: gwfMainBlue,
                fontWeight: "normal",
                borderBottom: "solid 1px #DFDFDF",
              }}
            />
            <CardContent sx={{ padding: 0 }}>
              {showProgress && <LinearProgress />}

              <Box padding="1rem">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      error={!!errors.name}
                      helperText={errors.name}
                      autoFocus
                      margin="dense"
                      name="name"
                      label="Name"
                      type="text"
                      variant="standard"
                      required
                      fullWidth
                      onChange={handleInputChange}
                      disabled={showProgress}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!!errors.surname}
                      helperText={errors.surname}
                      margin="dense"
                      name="surname"
                      label="Surname"
                      type="text"
                      variant="standard"
                      required
                      fullWidth
                      onChange={handleInputChange}
                      disabled={showProgress}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!!errors.email}
                      helperText={errors.email}
                      margin="dense"
                      name="email"
                      label="Email Address"
                      type="email"
                      variant="standard"
                      required
                      fullWidth
                      onChange={handleInputChange}
                      disabled={showProgress}
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!!errors.sponsorId}
                      helperText={errors.sponsorId}
                      name={"sponsorId"}
                      label={"Sponsor"}
                      select
                      onChange={onInputComboChange}
                      value={appUser.user.sponsorId || ""}
                      disabled={adminSponsorId !== "" || showPassword}
                      variant="standard"
                      required
                      margin={"dense"}
                      fullWidth
                    >
                      <MenuItem value="" key="no_sponsor">
                        <Typography fontStyle={"italic"}>no sponsor selected</Typography>
                      </MenuItem>
                      {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                        return (
                          <MenuItem value={sponsorId} key={sponsorId}>
                            {sponsor.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!!errors.agentId}
                      helperText={errors.agentId}
                      name={"agentId"}
                      label={"Agent"}
                      select
                      onChange={onInputComboChange}
                      value={appUser.user.agentId || ""}
                      margin={"dense"}
                      variant="standard"
                      disabled={filteredAgents.length === 0 || showPassword}
                      fullWidth
                    >
                      <MenuItem value="" key="no_agent">
                        <Typography fontStyle={"italic"}>no agent selected</Typography>
                      </MenuItem>
                      {Object.values(filteredAgents).map(([aId, agent], index) => {
                        return (
                          <MenuItem value={aId} key={aId}>
                            {agent.company}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  {/* Roles */}
                  <Grid item xs={12}>
                    <FormLabel component="legend" sx={{ mt: 3 }}>
                      Roles
                    </FormLabel>
                    <FormControl component="fieldset" variant="standard" sx={{ mr: 3, ml: 2 }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={appUser.user.role.includes("admin")}
                              onChange={handleRoleChange}
                              name="role-admin"
                              disabled={showProgress}
                            />
                          }
                          label="Administrator"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={appUser.user.role.includes("admin") || showProgress}
                              checked={appUser.user.role.includes("recruiter")}
                              onChange={handleRoleChange}
                              name="role-recruiter"
                            />
                          }
                          label="Recruiter"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" variant="standard" disabled={showProgress}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={appUser.user.role.includes("admin") || showProgress}
                              checked={appUser.user.role.includes("operator")}
                              onChange={handleRoleChange}
                              name="role-operator"
                            />
                          }
                          label="Visa Operator"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={appUser.user.role.includes("admin") || showProgress}
                              checked={appUser.user.role.includes("cos_operator")}
                              onChange={handleRoleChange}
                              name="role-cos_operator"
                            />
                          }
                          label="CoS Operator"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions sx={{ padding: "1rem", paddingTop: "0" }}>
          <Button disabled={showProgress} onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={showProgress}
            onClick={handleAddUser}
            color="primary"
            variant="contained"
          >
            Add new user
          </Button>
        </DialogActions>
      </Container>
    );
  };

  const getDialogContentSendCredentials = () => {
    return (
      <Container maxWidth="sm" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={alert.open}
            autoHideDuration={3000}
            onClose={() => setAlert({ open: false })}
          >
            <Alert
              severity={alert.severity}
              sx={{ width: "100%" }}
              onClose={() => setAlert({ open: false })}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <Card sx={{ border: "solid 1px #DFDFDF" }}>
            <CardContent
              sx={{
                padding: "0",
              }}
            >
              {showProgress && <LinearProgress />}
              <Box
                sx={{
                  padding: "2rem",
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textAlign: "center", mb: 2 }}
                >
                  Your new user can start using the system within in a few minutes.
                </Typography>
                <Avatar
                  sx={{
                    width: 64,
                    height: 64,
                    mt: "1rem",
                    color: "#BDBDBD",
                    backgroundColor: "#E1E1E1",
                  }}
                />
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ color: theme.palette.primary.main, mt: "1.5rem" }}
                >
                  {appUser.user.name} {appUser.user.surname}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                    minWidth: "450px",
                    mt: 2,
                  }}
                >
                  <Grid container columnGap={2} rowGap={1}>
                    <Grid item xs={5}>
                      <Typography variant="body2" color="textSecondary" textAlign={"right"}>
                        Username:
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {" "}
                      <Typography variant="body2" color="textSecondary" textAlign={"left"}>
                        {appUser.user.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2" color="textSecondary" textAlign={"right"}>
                        Password:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        // fontSize={showPassword ? "0.875rem" : "2rem"}
                        textAlign={"left"}
                      >
                        {showPassword ? password : "●●●●●●●●●●"}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        sx={{ color: "lightGray", margin: "0", padding: "0" }}
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </Grid>
                  </Grid>{" "}
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <Button variant="text" sx={{ mr: 2 }} onClick={handleCopyClipboard}>
                    Copy Password
                  </Button>
                  <Button
                    variant="text"
                    onClick={handleSendCredentials}
                    startIcon={<Mail />}
                    disabled={showProgress}
                  >
                    Send
                  </Button>
                </Box>
              </Box>{" "}
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions sx={{ paddingRight: "24px", paddingTop: "0" }}>
          <Button onClick={onClose} color="primary" variant="contained" disabled={showProgress}>
            Done
          </Button>
        </DialogActions>
      </Container>
    );
  };

  const getDialogContent = (step: number) => {
    switch (step) {
      case STEP_ADD_USER:
        return getDialogContentAddNewUser();
      case STEP_SEND_CREDENTIALS:
        return getDialogContentSendCredentials();
      default:
        return gwfMainBlue;
    }
  };

  const filteredAgents = useMemo(
    () =>
      Object.entries(agents).filter(([id, agent]) => {
        return appUser.user.sponsorId !== "" && agent.sponsorId === appUser.user.sponsorId;
      }),
    [appUser.user.sponsorId, agents]
  );

  return (
    <Dialog fullScreen maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle bgcolor={getDialogTitleColor(step)} sx={{ color: "white" }}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ marginRight: "1rem" }}
        >
          <CloseIcon />
        </IconButton>
        {getDialogTitleText(step)}
      </DialogTitle>
      {getDialogContent(step)}
    </Dialog>
  );
};

export default AddUserDialog;
